import { Auth } from '@aws-amplify/auth';
import fileDownload from 'js-file-download';
import axios, { AxiosInstance } from 'axios';
import _ from 'lodash';
import * as pako from 'pako';
import {
  ActionTakenType,
  DesignActionTakenType,
  DocumentPriorityType,
  DocumentTemplateType,
  EmailStatus,
  FileCategoryType,
  FileNode,
  IBid,
  IBidSetup,
  IDocument,
  IFile,
  INumberedDocumentView,
  IProductPackage,
  IProjectUser,
  IProjectView,
  ISecurityGroup,
  IUser,
  IUserGroup,
  PunchListStatusType,
  SecurityPermissionLevel,
  WorkflowStatusType,
} from '../api-client/autogenerated';
import {
  getBluebeamXML,
  getFileById,
  getFileUrlById,
  getFileUrlByIdForConsultant,
} from '../models/api/files';
import {
  addSpacing,
  ascendingComparator,
  descendingComparator,
  statusToGeneralString,
} from '../main-components/document-index/DocumentIndexUtils';
import path from 'path';
import dayjs from 'dayjs';
import { v4 } from 'uuid';
import {
  getUsersFromUserGroup,
  ManagePermissionsDialogType,
} from '../main-components/design/ManagePermissionsDialog';
import { hasPermissionToView } from './store-utils';
import { Project } from '../features/project/project';
import axiosRateLimit from 'axios-rate-limit';
import { getTemplateIds } from '../models/api/templates';
import { getDocumentsByProjectId } from '../models/api/project';
import { ExpandedJobStatusType } from '../main-components/document-conforming-center/DCCUtils';
import QRCode from 'easyqrcodejs';
import qrcodeLogo from '../images/centerline-logo-new.png';
import { FileWithPath } from 'react-dropzone';
import { verifyFileByUrl } from '../models/api/filesystem';

export type SeverityType = 'error' | 'success' | 'info' | 'warning' | undefined;

const MAX_REQUESTS_COUNT = 5;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

export const getRateLimitedAxiosClient = (
  axiosInstance?: AxiosInstance,
  perSecond: number = 20,
) => {
  if (!axiosInstance) {
    axiosInstance = axios.create();
  }
  const api = axiosRateLimit(axiosInstance, {
    maxRPS: perSecond,
  });

  api.interceptors.request.use(function (config) {
    return new Promise((resolve, reject) => {
      let interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
          PENDING_REQUESTS++;
          clearInterval(interval);
          resolve(config);
        }
      }, INTERVAL_MS);
    });
  });

  api.interceptors.response.use(
    function (response) {
      PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
      return Promise.resolve(response);
    },
    function (error) {
      PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
      return Promise.reject(error);
    },
  );
  return api;
};

export const getAxiosClient = () => {
  return getRateLimitedAxiosClient();
};

export const combineClasses = (...args: string[]) => {
  let classes = '';
  if (args.length > 0) {
    classes = args.join(' ');
  }
  return classes;
};

export const getDrawingAndSpecificationFiles = (project: IProjectView | null) => {
  if (!project) return { drawingFiles: [], specificationFiles: [] };
  const files = (project.files || [])
    .sort((f1, f2) => {
      if (f1.createdOn! < f2.createdOn!) return -1;
      if (f1.createdOn === f2.createdOn) return 0;
      return 1;
    })
    .reverse();
  return {
    drawingFiles: files.filter((f) => f.category === FileCategoryType.Drawings),
    specificationFiles: files.filter((f) => f.category === FileCategoryType.Specifications),
  };
};

export const getBidDrawingAndSpecificationFiles = (bid: IBidSetup) => {
  const files = (bid.files || [])
    .sort((f1, f2) => {
      if (f1.createdOn! < f2.createdOn!) return -1;
      if (f1.createdOn === f2.createdOn) return 0;
      return 1;
    })
    .reverse();
  return {
    drawingFiles: files.filter((f) => f.category === FileCategoryType.BidDrawings),
    specificationFiles: files.filter((f) => f.category === FileCategoryType.BidSpecifications),
  };
};

export const documentIsOverdue = (document: INumberedDocumentView, isSubmittal = false) => {
  const initialStatuses = [
    WorkflowStatusType.Initial,
    WorkflowStatusType.SubcontractorUploaded,
    WorkflowStatusType.ReadyForSubmissionToArchitect,
    WorkflowStatusType.GeneralContractorUploaded,
  ];
  const completeStatuses = [
    WorkflowStatusType.ReviewComplete,
    WorkflowStatusType.Completed,
    WorkflowStatusType.Resolved,
    WorkflowStatusType.Resubmitted,
  ];
  if (
    (isSubmittal || document.documentTemplate?.name === DocumentTemplateType.Submittals) &&
    document.workflowStatus &&
    initialStatuses.includes(document.workflowStatus)
  )
    return (
      !!document.anticipatedInitialSubmissionDate &&
      dayjs().add(-1, 'day').isAfter(parseDate(document.anticipatedInitialSubmissionDate))
    );
  return (
    (!document.workflowStatus || !completeStatuses.includes(document.workflowStatus)) &&
    !!document.dueDate &&
    dayjs().add(-1, 'day').isAfter(parseDate(document.dueDate))
  );
};

export const documentIsRevision = (document: IDocument) => {
  return (document.revisionNumber || 0) > 0;
};

export const formatFileSize = (x: number) => {
  const xInKilos = x / 1024;
  if (xInKilos > 1024) {
    const xInMegs = xInKilos / 1024;
    return xInMegs.toFixed(1).toString().concat(' MB');
  }
  return Math.round(xInKilos).toString().concat(' KB');
};

export const formatDate = (dateStr?: string | null, omitTime = false) => {
  if (!dateStr) return 'N/A';
  const date = parseDate(dateStr).toDate();
  const year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : `0${month}`;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : `0${day}`;

  const time = date.toLocaleTimeString([], {
    // @ts-ignore
    timeStyle: 'short',
  });
  if (month.toLowerCase() === 'nan' || day.toLowerCase() === 'nan') return 'N/A';
  if (omitTime) return `${month}/${day}/${year}`;
  return `${month}/${day}/${year} ${time}`;
};

export const formatDateUpcoming = (dateStr: string) => {
  const date = parseDate(dateStr).toDate();

  const month = (1 + date.getMonth()).toString();
  let monthString = '';
  switch (month) {
    case '1':
      monthString = 'Jan.';
      break;
    case '2':
      monthString = 'Feb.';
      break;
    case '3':
      monthString = 'Mar.';
      break;
    case '4':
      monthString = 'Apr.';
      break;
    case '5':
      monthString = 'May';
      break;
    case '6':
      monthString = 'June';
      break;
    case '7':
      monthString = 'July';
      break;
    case '8':
      monthString = 'Aug.';
      break;
    case '9':
      monthString = 'Sept.';
      break;
    case '10':
      monthString = 'Oct.';
      break;
    case '11':
      monthString = 'Nov.';
      break;
    case '12':
      monthString = 'Dec.';
      break;
    default:
      break;
  }

  const day = date.getDate().toString();
  return `${day}\n${monthString}`;
};

export const formatPhoneNumber = (str: string) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatDays = (days?: number | null): string => {
  if (days == null) return `TBD`;
  const daysInt = Math.floor(days || 0);
  if (daysInt === 1) {
    return `${daysInt} day`;
  }
  return `${daysInt} days`;
};

export const formatMoney = (num?: number | null): string => {
  if (num == null) return '';
  if (Number.isNaN(num)) {
    return '';
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num / 100);
};

export const addDays = (date: Date | string, days: number) => {
  const result = dayjs(date).toDate();
  result.setDate(result.getDate() + days);
  return result;
};

export const formatDateLong = (dateStr: string) => {
  const date = parseDate(dateStr).toDate();
  const dateElements = date.toDateString().split(' ');
  const month = date.toLocaleString('default', { month: 'long' });
  const dayName = date.toLocaleString('default', { weekday: 'long' });
  const time = date.toLocaleTimeString([], {
    // @ts-ignore
    timeStyle: 'short',
  });
  // The submission deadline is Tuesday on December 15, 2020 at 4:00 AM.
  return `${dayName} on ${month} ${dateElements[2]}, ${dateElements[3]} at ${time}.`;
};

export const toggleState = (boolState: boolean, setBoolState: (state: boolean) => void) => {
  setBoolState(!boolState);
};

export const setupAmplify = () => {
  Auth.configure({
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID || 'us-east-1_yT7PBd3hs',
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEBCLIENT_ID || '1bunfc5fejade7mtji04jqpj8v',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  });
};

// TODO: make sure we're actually writing the write data and passing url/name to the file before opening
export const openInNewTab = (fileId: string, bidding?: boolean) => {
  const newWindow = window.open(
    `${window.location.origin}/${bidding ? 'bidding' : 'main'}/${
      isPublicPage() ? 'public/' : ''
    }pdf/${fileId}`,
    '_blank',
    'noopener,noreferrer',
  );
  if (newWindow) newWindow.opener = null;
};

export const openInNewWindow = (fileId: string, bidding?: boolean) => {
  const newWindow = window.open(
    `${window.location.origin}/${bidding ? 'bidding' : 'main'}/${
      isPublicPage() ? 'public/' : ''
    }pdf/${fileId}`,
    '_blank',
    `location=yes,height=${window.innerHeight * 0.9},width=840,scrollbars=yes,status=yes`,
  );
  if (newWindow) newWindow.opener = null;
};

export const fileIsPdf = (file: IFile) => {
  return file.url?.toLowerCase().endsWith('.pdf');
};

export const fileIsImage = (file: IFile) => {
  return (
    file.url?.toLowerCase().endsWith('.jpg') ||
    file.url?.toLowerCase().endsWith('.jpeg') ||
    file.url?.toLowerCase().endsWith('.png')
  );
};

export const openFilesystemFileInNewTab = (
  projectId: string,
  fullName: string,
  fullKey: string,
  ownerId: string,
  isRecycleBin?: boolean,
) => {
  if (isRecycleBin) {
    localStorage.setItem('s3Key', fullKey);
  } else {
    localStorage.setItem('projectId', projectId);
    localStorage.setItem('fullKey', fullKey);
    localStorage.setItem('ownerId', ownerId);
  }
  localStorage.setItem('fullName', fullName);
  const newWindow = window.open(
    `${window.location.origin}/main/pdf/design`,
    '_blank',
    'noopener,noreferrer',
  );
  if (newWindow) newWindow.opener = null;
};

export const openPublicFilesystemFileInNewTab = (
  projectId: string,
  fullName: string,
  fullKey: string,
  designFileShareId: string,
  publicAccessKey: string,
) => {
  localStorage.setItem('projectId', projectId);
  localStorage.setItem('fullName', fullName);
  localStorage.setItem('designFileShareId', designFileShareId);
  localStorage.setItem('fullKey', fullKey);
  localStorage.setItem('publicAccessKey', publicAccessKey);
  const newWindow = window.open(
    `${window.location.origin}/main/pdf/design`,
    '_blank',
    'noopener,noreferrer',
  );
  if (newWindow) newWindow.opener = null;
};

export const openInBluebeam = (
  fileId: string,
  filename = 'bluebeam',
  additionalReviewDocumentId?: string,
) => {
  return getBluebeamXML(fileId, additionalReviewDocumentId)
    .then((xml) => {
      fileDownload(xml, `${path.basename(filename, '.pdf')}.bfx`, 'application/bfx');
    })
    .catch((e) => console.error(e));
};

export const getFileBlob = async (
  fileId: string,
  fullSize?: boolean,
  onDownloadProgress?: (progressEvent: any) => void,
): Promise<Blob | undefined> => {
  const { downloadLink } = await getFileUrlById(fileId, fullSize);
  if (downloadLink) {
    const { data } = await getAxiosClient().get<Blob>(downloadLink, {
      responseType: 'blob',
      onDownloadProgress,
    });
    return data;
  }
  return undefined;
};

export const downloadFileById = async (
  fileId: string,
  filename: string,
  fileType?: string,
  fullSize = true,
) => {
  const { downloadLink } = await getFileUrlById(fileId, fullSize, filename);
  if (downloadLink) {
    downloadFileFromLink(downloadLink, filename);
  }
};

export const downloadFileFromLink = (link: string, filename: string) => {
  const anchor = document.createElement('a');
  anchor.href = link;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const openFileInNewTab = async (fileId: string) => {
  const { downloadLink } = await getFileUrlById(fileId, true);
  if (!downloadLink) return;
  const newWindow = window.open(downloadLink, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const downloadFileAsConsultant = async (
  fileId: string,
  filename: string,
  reviewDocumentId: string,
  fileType?: string,
) => {
  const { downloadLink } = await getFileUrlByIdForConsultant(fileId, reviewDocumentId);
  if (downloadLink) {
    downloadFileFromLink(downloadLink, filename);
  }
};

export const getImageByFileId = async (fileId: string, fullSize?: boolean) => {
  const response = await getFileUrlById(fileId, fullSize);
  const { data } = await getAxiosClient().get<Blob>(response.downloadLink, {
    responseType: 'blob',
  });
  return URL.createObjectURL(data);
};

export const documentTypeLookup: Record<string, string> = {
  'Const. Change Directives': DocumentTemplateType.ConstructionChangeDirectives,
  'Work Change Proposal Req.': DocumentTemplateType.WorkChangeProposalRequests,
  'Substantial Completion Cert.': DocumentTemplateType.CertificateOfSubstantialCompletion,
  'O&M Data': DocumentTemplateType.OperationsAndMaintenanceData,
  'As-Built Drawings': DocumentTemplateType.AsBuilt,
  'Bid Submissions': DocumentTemplateType.BidTabulation,
  'Task & Share Manager': DocumentTemplateType.Task,
  'Design Package Review': DocumentTemplateType.DesignPackages,
};

export const documentTypeToReadable: Record<DocumentTemplateType, string> = {
  [DocumentTemplateType.Addenda]: 'Addendum',
  [DocumentTemplateType.AdditionalReview]: 'Additional Review',
  [DocumentTemplateType.AsBuilt]: 'As-Built',
  [DocumentTemplateType.AsBuiltPackages]: 'As-Built Packages',
  [DocumentTemplateType.AsiDocuments]: 'ASI',
  [DocumentTemplateType.BidAdvertisement]: 'Bid Advertisement',
  [DocumentTemplateType.BidderRfIs]: 'Bidder RFI',
  [DocumentTemplateType.BidDrawings]: 'Bid Drawing',
  [DocumentTemplateType.BidTabulation]: 'Bid Tabulation',
  [DocumentTemplateType.CertificateOfSubstantialCompletion]: 'Substantial Completion Cert.',
  [DocumentTemplateType.ChangeOrders]: 'Change Order',
  [DocumentTemplateType.ClearLp]: 'Clear L&P',
  [DocumentTemplateType.CloseoutSubmittals]: 'Closeout Submittal',
  [DocumentTemplateType.ConstructionChangeDirectives]: 'Const. Change. Directive',
  [DocumentTemplateType.ContractorDailyLogs]: 'Daily Log',
  [DocumentTemplateType.Drawings]: 'Drawings',
  [DocumentTemplateType.FieldReports]: 'Field Report',
  [DocumentTemplateType.InformationalItems]: 'Info Item',
  [DocumentTemplateType.MeetingMinutes]: 'Meeting Minutes',
  [DocumentTemplateType.MiscellaneousDocuments]: 'Misc Document',
  [DocumentTemplateType.OperationsAndMaintenanceData]: 'O&M',
  [DocumentTemplateType.PayApplications]: 'Pay Application',
  [DocumentTemplateType.PlanholderList]: 'Planholder List',
  [DocumentTemplateType.PotentialChangeOrders]: 'PCO',
  [DocumentTemplateType.SubstitutionRequests]: 'Substitution Request',
  [DocumentTemplateType.ProjectManual]: 'Project Manual',
  [DocumentTemplateType.PunchList]: 'Punch List',
  [DocumentTemplateType.RegulatoryApprovals]: 'Regulatory Approval',
  [DocumentTemplateType.RequestsForChange]: 'RFC',
  [DocumentTemplateType.RequestsForInformation]: 'RFI',
  [DocumentTemplateType.Schedules]: 'Schedule',
  [DocumentTemplateType.Specifications]: 'Specification',
  [DocumentTemplateType.SubmittalPackages]: 'Submittal Package',
  [DocumentTemplateType.CloseoutSubmittalPackages]: 'Closeout Submittal Package',
  [DocumentTemplateType.Submittals]: 'Submittal',
  [DocumentTemplateType.Task]: 'Item',
  [DocumentTemplateType.DesignPackages]: 'Item',
  [DocumentTemplateType.Testing]: 'Testing',
  [DocumentTemplateType.WarrantyItems]: 'Warranty Item',
  [DocumentTemplateType.WorkChangeProposalRequests]: 'WCPR',
};

export const documentTypeToUrl: Record<DocumentTemplateType, string> = {
  [DocumentTemplateType.Addenda]: 'addenda',
  [DocumentTemplateType.AdditionalReview]: 'additional-review',
  [DocumentTemplateType.AsBuilt]: 'as-built',
  [DocumentTemplateType.AsBuiltPackages]: 'as-built-packages',
  [DocumentTemplateType.AsiDocuments]: 'asi-docs',
  [DocumentTemplateType.BidAdvertisement]: 'bid-advertisement',
  [DocumentTemplateType.BidderRfIs]: 'bidder-rfis',
  [DocumentTemplateType.BidDrawings]: 'bid-drawings',
  [DocumentTemplateType.BidTabulation]: 'bid-submissions',
  [DocumentTemplateType.CertificateOfSubstantialCompletion]: 'substantial-completion-cert',
  [DocumentTemplateType.ChangeOrders]: 'change-orders',
  [DocumentTemplateType.ClearLp]: 'clear-lp',
  [DocumentTemplateType.CloseoutSubmittals]: 'closeout-submittals',
  [DocumentTemplateType.ConstructionChangeDirectives]: 'const-change-directives',
  [DocumentTemplateType.ContractorDailyLogs]: 'daily-logs',
  [DocumentTemplateType.Drawings]: 'drawings',
  [DocumentTemplateType.FieldReports]: 'field-reports',
  [DocumentTemplateType.InformationalItems]: 'info-items',
  [DocumentTemplateType.MeetingMinutes]: 'meeting-minutes',
  [DocumentTemplateType.MiscellaneousDocuments]: 'misc-docs',
  [DocumentTemplateType.OperationsAndMaintenanceData]: 'om-data',
  [DocumentTemplateType.PayApplications]: 'pay-applications',
  [DocumentTemplateType.PlanholderList]: 'planholder-list',
  [DocumentTemplateType.PotentialChangeOrders]: 'potential-change-orders',
  [DocumentTemplateType.SubstitutionRequests]: 'substitution-requests',
  [DocumentTemplateType.ProjectManual]: 'project-manual',
  [DocumentTemplateType.PunchList]: 'punch-list',
  [DocumentTemplateType.RegulatoryApprovals]: 'regulatory-approvals',
  [DocumentTemplateType.RequestsForChange]: 'rfc',
  [DocumentTemplateType.RequestsForInformation]: 'rfi',
  [DocumentTemplateType.Schedules]: 'schedules',
  [DocumentTemplateType.Specifications]: 'specifications',
  [DocumentTemplateType.SubmittalPackages]: 'submittals',
  [DocumentTemplateType.CloseoutSubmittalPackages]: 'closeout-packages',
  [DocumentTemplateType.Submittals]: 'submittals',
  [DocumentTemplateType.Task]: 'tasks',
  [DocumentTemplateType.DesignPackages]: 'design-packages',
  [DocumentTemplateType.Testing]: 'testing',
  [DocumentTemplateType.WarrantyItems]: 'warranty-items',
  [DocumentTemplateType.WorkChangeProposalRequests]: 'work-change-prop-requests',
};

export enum SubscriberAccessType {
  DESIGN = 'Design',
  BIDDING = 'Bidding',
  CONSTRUCTION_CLOSEOUT = 'Construction/Closeout',
}

export const documentTypeToAccessType: Record<
  DocumentTemplateType,
  SubscriberAccessType | undefined
> = {
  [DocumentTemplateType.Addenda]: SubscriberAccessType.BIDDING,
  [DocumentTemplateType.AdditionalReview]: undefined,
  [DocumentTemplateType.AsBuilt]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.AsBuiltPackages]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.AsiDocuments]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.BidAdvertisement]: undefined,
  [DocumentTemplateType.BidderRfIs]: SubscriberAccessType.BIDDING,
  [DocumentTemplateType.BidDrawings]: SubscriberAccessType.BIDDING,
  [DocumentTemplateType.BidTabulation]: SubscriberAccessType.BIDDING,
  [DocumentTemplateType.CertificateOfSubstantialCompletion]:
    SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.ChangeOrders]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.ClearLp]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.CloseoutSubmittals]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.ConstructionChangeDirectives]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.ContractorDailyLogs]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.Drawings]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.FieldReports]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.InformationalItems]: SubscriberAccessType.BIDDING,
  [DocumentTemplateType.MeetingMinutes]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.MiscellaneousDocuments]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.OperationsAndMaintenanceData]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.PayApplications]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.PlanholderList]: SubscriberAccessType.BIDDING,
  [DocumentTemplateType.PotentialChangeOrders]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.SubstitutionRequests]: SubscriberAccessType.BIDDING,
  [DocumentTemplateType.ProjectManual]: SubscriberAccessType.BIDDING,
  [DocumentTemplateType.PunchList]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.RegulatoryApprovals]: SubscriberAccessType.BIDDING,
  [DocumentTemplateType.RequestsForChange]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.RequestsForInformation]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.Schedules]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.Specifications]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.SubmittalPackages]: undefined,
  [DocumentTemplateType.CloseoutSubmittalPackages]: undefined,
  [DocumentTemplateType.Submittals]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.Task]: SubscriberAccessType.DESIGN,
  [DocumentTemplateType.DesignPackages]: SubscriberAccessType.DESIGN,
  [DocumentTemplateType.Testing]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.WarrantyItems]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
  [DocumentTemplateType.WorkChangeProposalRequests]: SubscriberAccessType.CONSTRUCTION_CLOSEOUT,
};

export const userCanViewDocument = (
  url: string,
  canViewDesign: boolean,
  canViewBidding: boolean,
  canViewConstructionAndCloseout: boolean,
): boolean => {
  const splitUrl = url.split('/');
  if (splitUrl.includes('design')) {
    return canViewDesign;
  }
  const index = splitUrl.findIndex((x) => x === 'documents');
  const documentType: string = urlToDocumentType[splitUrl[index + 1]];
  const accessType: SubscriberAccessType | undefined =
    documentTypeToAccessType[documentType as DocumentTemplateType];
  if (accessType === SubscriberAccessType.BIDDING) {
    return canViewBidding;
  } else if (accessType === SubscriberAccessType.CONSTRUCTION_CLOSEOUT) {
    return canViewConstructionAndCloseout;
  } else if (accessType === SubscriberAccessType.DESIGN) {
    return canViewDesign;
  } else {
    return false;
  }
};

export const urlToDocumentType = _.invert(documentTypeToUrl);
export const urlToDocumentTypeReadable = (url: string) =>
  documentTypeToReadable[urlToDocumentType[url] as DocumentTemplateType];

export type PdfMatchParams = { fileId: string };
export type ProjectMatchParams = { projectId: string };
export type OptionalProjectMatchParams = Partial<ProjectMatchParams>;
export type IndexMatchParams = { projectId: string; type: string };
export type DocumentMatchParams = {
  projectId: string;
  type: string;
  documentId: string;
  commentId?: string;
};
export type DocumentOrLocationMatchParams = {
  projectId: string;
  type: string;
  documentId?: string;
  locationId?: string;
};
export type PublicDocumentMatchParams = {
  key: string;
};
export type RemindersMatchParams = { key?: string };
export type DCCMatchParams = { fileId?: string };
export type PublicDesignMatchParams = {
  projectId: string;
  designFileShareId: string;
  publicAccessKey: string;
};

export const uuidPattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

export const showDocumentDisplay = (type: string) => {
  const lookup = urlToDocumentType[type];
  return (
    lookup === DocumentTemplateType.Submittals ||
    lookup === DocumentTemplateType.Specifications ||
    lookup === DocumentTemplateType.CloseoutSubmittals
  );
};

export const getUserFacingDocumentStatus = (doc: INumberedDocumentView | null) => {
  if (!doc) return '';

  if (
    doc.priority === DocumentPriorityType.ForInformationOnly ||
    doc.actionTaken === ActionTakenType.ForInformationOnly
  ) {
    return 'For Information Only';
  }

  if (doc.actionTaken === ActionTakenType.Withdrawn) {
    return 'Withdrawn';
  }

  if (
    doc.documentTemplate?.name === DocumentTemplateType.PunchList ||
    doc.documentTemplate?.name === DocumentTemplateType.WarrantyItems
  )
    return statusToGeneralString[doc.punchListStatus as PunchListStatusType];

  const { workflowStatus } = doc as IDocument;

  if (
    doc.documentTemplate?.name === DocumentTemplateType.AsBuilt ||
    doc.documentTemplate?.name === DocumentTemplateType.AsBuiltPackages
  )
    return getAsBuiltStatus(doc);

  if (
    doc.documentTemplate?.name === DocumentTemplateType.Task ||
    doc.documentTemplate?.name === DocumentTemplateType.DesignPackages
  ) {
    return getUserFriendlyTaskStatusFromWorkflowStatus(workflowStatus);
  }

  return getUserFacingWorkflowStatus(workflowStatus);
};

export const getUserFacingWorkflowStatus = (status: WorkflowStatusType | null | undefined) => {
  switch (status) {
    case WorkflowStatusType.Initial:
    case WorkflowStatusType.SubcontractorUploaded:
    case WorkflowStatusType.ReadyForSubmissionToArchitect:
    case WorkflowStatusType.GeneralContractorUploaded:
      return 'Awaiting Submission';
    case WorkflowStatusType.SubmittedForReview:
      return 'Submitted for Review';
    case WorkflowStatusType.UnderReview:
    case WorkflowStatusType.ArchitectUploaded:
      return 'Under Review';
    case WorkflowStatusType.ReviewComplete:
    case WorkflowStatusType.Completed:
    case WorkflowStatusType.Resubmitted:
    case WorkflowStatusType.Accepted:
      return 'Review Complete';
    case WorkflowStatusType.Resolved:
      return 'Resolved';
    case WorkflowStatusType.Canceled:
      return 'Canceled';
    default:
      return 'N/A';
  }
};

export const getActionTakenText = (action: string): string => {
  if (action === 'N/A' || !action) return 'None';
  const asdf: Record<ActionTakenType, string> = {
    [ActionTakenType.AmendAsNoted]: 'Amend as Noted',
    [ActionTakenType.NoExceptionsTaken]: 'No Exceptions Taken',
    [ActionTakenType.ReviseAndResubmit]: 'Revise & Resubmit',
    [ActionTakenType.SeeSubmittalComments]: 'See Submittal Comments',
    [ActionTakenType.SeeTransmittalComments]: 'See Transmittal Comments',
    [ActionTakenType.SubmitSpecifiedItem]: 'Submit Specified Item',
    [ActionTakenType.Withdrawn]: 'Withdrawn',
    [ActionTakenType.ForInformationOnly]: 'For Information Only',
    [ActionTakenType.RemoveFromPackage]: 'Remove from Package',
  };
  if (Object.values(ActionTakenType).includes(action as ActionTakenType)) {
    return asdf[action as ActionTakenType];
  }
  return 'Unknown Status';
};

export const getDocumentEditFields = (type?: DocumentTemplateType | null) => {
  switch (type) {
    case DocumentTemplateType.Addenda:
      return ['Reference #', 'Addendum #', 'Upload'];
    case DocumentTemplateType.AsiDocuments:
      return ['ASI #', 'Title', 'Description', 'Created By', 'Upload'];
    case DocumentTemplateType.RegulatoryApprovals:
    case DocumentTemplateType.ConstructionChangeDirectives:
      return ['CCD #', 'Title', 'Description', 'Created By', 'Upload'];
    case DocumentTemplateType.ContractorDailyLogs:
      return ['Log Date', 'Title', 'Description'];
    case DocumentTemplateType.FieldReports:
      return ['Field Report #', 'Title', 'General Observation'];
    case DocumentTemplateType.MeetingMinutes:
      return ['Meeting Date', 'Title', 'Description'];
    case DocumentTemplateType.ChangeOrders:
      return [
        'Title',
        'Change Order #',
        'Description',
        'Change Orders Value',
        'Change Orders Days Added',
        'Upload',
      ];
    case DocumentTemplateType.MiscellaneousDocuments:
    case DocumentTemplateType.Schedules:
      return ['Title', 'Description'];
    case DocumentTemplateType.CertificateOfSubstantialCompletion:
    case DocumentTemplateType.ClearLp:
      return ['Reference #', 'Description', 'Upload'];
    case DocumentTemplateType.PayApplications:
      return ['Pay App #', 'Title', 'Description'];
    case DocumentTemplateType.PotentialChangeOrders:
      return [
        'PCO #',
        'Title',
        'Description',
        'Impact to Cost',
        'Impact to Time',
        'Responsibility',
        'Due',
        'Upload',
      ];
    case DocumentTemplateType.RequestsForChange:
      return [
        'RFC #',
        'Title',
        'Description',
        'Impact to Cost',
        'Impact to Time',
        'Responsibility',
        'Due',
        'Upload',
      ];
    case DocumentTemplateType.Submittals:
      return [
        'Specification Section',
        'Title',
        'Anticipated Initial Submission Date',
        'Due',
        'Responsibility',
        'Upload',
      ];
    case DocumentTemplateType.CloseoutSubmittals:
      return [
        'Specification Section',
        'Title',
        'Anticipated Initial Submission Date',
        'Due',
        'Responsibility',
      ];
    case DocumentTemplateType.SubmittalPackages:
    case DocumentTemplateType.CloseoutSubmittalPackages:
      return ['Package #', 'Title', 'Anticipated Initial Submission Date', 'Due', 'Responsibility'];
    case DocumentTemplateType.Testing:
      return ['Testing #', 'Title', 'Description', 'Agency'];
    case DocumentTemplateType.RequestsForInformation:
      return [
        'RFI #',
        'Title',
        'Description',
        'Due',
        'Submitted',
        'Responsibility',
        'Impact',
        'Upload',
      ];
    case DocumentTemplateType.WorkChangeProposalRequests:
      return ['WCPR #', 'Description', 'Responsibility', 'dueOptional', 'Upload'];
    case DocumentTemplateType.AsBuilt:
      return ['Upload'];
    case DocumentTemplateType.OperationsAndMaintenanceData:
      return ['Reference #', 'Specification Section', 'Description', 'Upload'];
    case DocumentTemplateType.PunchList:
      return ['Reference #', 'Description', 'Location', 'GC', 'Architect', 'Due', 'Upload'];
    case DocumentTemplateType.WarrantyItems:
      return ['Reference #', 'Description', 'Location', 'Subcontractor', 'Images', 'Upload'];
    case DocumentTemplateType.BidDrawings:
    case DocumentTemplateType.Drawings:
      return ['Title', 'Simple Package', 'Upload'];
    case DocumentTemplateType.Specifications:
      return ['Title', 'Upload'];
    case DocumentTemplateType.Task:
      return ['Item #', 'Title', 'Description'];
    case DocumentTemplateType.DesignPackages:
      return ['Item #', 'Title', 'Description'];
    default:
      return ['Reference #', 'Upload'];
  }
};

export const docTypeHasDueDateEditField = (docType?: DocumentTemplateType | null) => {
  return getDocumentEditFields(docType).includes('Due');
};

export const getDocumentCreationFields = (type?: DocumentTemplateType | null) => {
  switch (type) {
    case DocumentTemplateType.Addenda:
      return ['Reference #', 'Addendum #', 'Upload'];
    case DocumentTemplateType.AsiDocuments:
      return ['ASI #', 'Title', 'Description', 'Created By', 'Upload'];
    case DocumentTemplateType.RegulatoryApprovals:
    case DocumentTemplateType.ConstructionChangeDirectives:
      return ['CCD #', 'Title', 'Description', 'Created By', 'Upload'];
    case DocumentTemplateType.ContractorDailyLogs:
      return ['Log Date', 'Title', 'Description', 'Upload'];
    case DocumentTemplateType.FieldReports:
      return ['Date of Observation', 'Title', 'General Observation', 'Upload'];
    case DocumentTemplateType.MeetingMinutes:
      return ['Meeting Date', 'Title', 'Description', 'Upload'];
    case DocumentTemplateType.ChangeOrders:
      return [
        'Change Order #',
        'Title',
        'Description',
        'Change Orders Value',
        'Change Orders Days Added',
        'Upload',
      ];
    case DocumentTemplateType.MiscellaneousDocuments:
    case DocumentTemplateType.Schedules:
      return ['Title', 'Description', 'Upload'];
    case DocumentTemplateType.CertificateOfSubstantialCompletion:
    case DocumentTemplateType.ClearLp:
      return ['Reference #', 'Description', 'Upload'];
    case DocumentTemplateType.PayApplications:
      return ['Pay App #', 'Title', 'Description', 'Final Executed?', 'Upload'];
    case DocumentTemplateType.PotentialChangeOrders:
      return [
        'PCO #',
        'Title',
        'Description',
        'Impact to Cost',
        'Impact to Time',
        'Responsibility',
        'Upload',
      ];
    case DocumentTemplateType.RequestsForChange:
      return [
        'RFC #',
        'Title',
        'Description',
        'Impact to Cost',
        'Impact to Time',
        'Responsibility',
        'Due Date',
        'Upload',
      ];
    case DocumentTemplateType.Submittals:
    case DocumentTemplateType.CloseoutSubmittals:
      return [
        'Specification Section',
        'Title',
        'Anticipated Initial Submission Date',
        'Due Date',
        'Priority',
        'Responsibility',
        'Upload',
      ];
    case DocumentTemplateType.Testing:
      return ['Testing #', 'Title', 'Description', 'Agency', 'Upload'];
    case DocumentTemplateType.RequestsForInformation:
      return [
        'RFI #',
        'Title',
        'Description',
        'Due Date',
        'Submitted',
        'Responsibility',
        'Impact',
        'Upload',
      ];
    case DocumentTemplateType.WorkChangeProposalRequests:
      return ['WCPR #', 'Title', 'Description', 'Responsibility', 'Upload'];
    case DocumentTemplateType.AsBuilt:
      return ['Drawings Sheet'];
    case DocumentTemplateType.OperationsAndMaintenanceData:
      return ['Reference #', 'Specification Section', 'Description', 'Upload'];
    case DocumentTemplateType.PunchList:
      return ['Reference #', 'Description', 'Location', 'Responsibility', 'Due Date', 'Upload'];
    case DocumentTemplateType.WarrantyItems:
      return ['Reference #', 'Location', 'Description', 'Subcontractor', 'Images', 'Upload'];
    case DocumentTemplateType.BidDrawings:
    case DocumentTemplateType.Drawings:
      return ['Title', 'Sheet #', 'Upload'];
    case DocumentTemplateType.Task:
      return ['Item #', 'Title', 'Description', 'Priority', 'Due Date', 'Responsibility', 'Upload'];
    case DocumentTemplateType.DesignPackages:
      return ['Item #', 'Title', 'Description', 'Due Date', 'MC_Responsibility', 'Upload'];
    default:
      return ['Reference #', 'Upload'];
  }
};

export const docTypeHasDueDateCreationField = (docType?: DocumentTemplateType | null) => {
  return getDocumentCreationFields(docType).includes('Due Date');
};

export const getDocumentTypesWithReferenceNumber = () => {
  return [
    DocumentTemplateType.Addenda,
    DocumentTemplateType.AsiDocuments,
    DocumentTemplateType.RegulatoryApprovals,
    DocumentTemplateType.ConstructionChangeDirectives,
    DocumentTemplateType.ChangeOrders,
    DocumentTemplateType.CertificateOfSubstantialCompletion,
    DocumentTemplateType.ClearLp,
    DocumentTemplateType.DesignPackages,
    DocumentTemplateType.PayApplications,
    DocumentTemplateType.PotentialChangeOrders,
    DocumentTemplateType.RequestsForChange,
    DocumentTemplateType.Testing,
    DocumentTemplateType.RequestsForInformation,
    DocumentTemplateType.WorkChangeProposalRequests,
    DocumentTemplateType.OperationsAndMaintenanceData,
    DocumentTemplateType.WarrantyItems,
    DocumentTemplateType.Task,
  ];
};

export const generatePassword = (length = 10) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const getSubtitle = (document?: INumberedDocumentView | null) => {
  const docTypesNoSubtitle = [
    DocumentTemplateType.RequestsForInformation,
    DocumentTemplateType.AsiDocuments,
    DocumentTemplateType.RequestsForChange,
    DocumentTemplateType.SubmittalPackages,
    DocumentTemplateType.CloseoutSubmittalPackages,
    DocumentTemplateType.ChangeOrders,
    DocumentTemplateType.PotentialChangeOrders,
    DocumentTemplateType.Drawings,
    DocumentTemplateType.BidDrawings,
    DocumentTemplateType.Task,
    DocumentTemplateType.DesignPackages,
    DocumentTemplateType.Specifications,
    DocumentTemplateType.WarrantyItems,
  ];
  if (document) {
    if (document.submittalSection && document.submittalSectionDescription)
      return `${addSpacing(document.submittalSection)} ${document.submittalSectionDescription}`;
    if (
      document.referenceNumber &&
      document.documentTemplate &&
      !docTypesNoSubtitle.includes(document.documentTemplate.name as DocumentTemplateType)
    )
      return `Reference #${document.referenceNumber}`;
  }
  return '';
};

export const getTitle = (
  docType: DocumentTemplateType,
  document: INumberedDocumentView | null,
  bid?: IBid | null,
  withSubmittalTitle = false,
) => {
  if (bid) {
    return `Bid Submission #${bid.bidNumber}`;
  }
  if (!document) return 'N/A';
  if (
    document.documentTemplate?.name === DocumentTemplateType.SubmittalPackages ||
    document.documentTemplate?.name === DocumentTemplateType.CloseoutSubmittalPackages
  )
    return `${getUserFriendlyDocumentTemplateNameSingular(
      (document.documentTemplate?.name as DocumentTemplateType) || undefined,
    )} - ${document.referenceNumber || document.documentNumber!.toString()}`;
  if (document && document.submittalSection) {
    return `${getUserFriendlyDocumentTemplateNameSingular(
      (document.documentTemplate?.name as DocumentTemplateType) || undefined,
    )} ${getSubmittalTitle(document, withSubmittalTitle)}`;
  }
  if (
    docType === DocumentTemplateType.RequestsForInformation ||
    docType === DocumentTemplateType.Task ||
    docType === DocumentTemplateType.DesignPackages
  ) {
    return `${documentTypeToReadable[docType]} #${document?.referenceNumber}${
      document.revisionNumber ? `-R${document.revisionNumber}` : ''
    }${document.title ? ` — ${document.title}` : ''}`;
  }
  if (docType === DocumentTemplateType.FieldReports)
    return `Field Report ${
      document.dateOfObservation
        ? `#${parseDate(document.dateOfObservation).format('YYYY-MM-DD')}`
        : ''
    }`;
  if (
    docType === DocumentTemplateType.Addenda ||
    docType === DocumentTemplateType.InformationalItems
  ) {
    return `${documentTypeToReadable[docType]} ${
      document.documentNumber
        ? `#${
            document.documentNumber.toString().length === 1
              ? `0${document.documentNumber}`
              : document.documentNumber
          }`
        : ''
    }${document.title ? ` — ${document.title}` : ''}`;
  }
  if (docType === DocumentTemplateType.Drawings) {
    return `${documentTypeToReadable[docType]} #${document.sheetNumber}${
      document.revisionNumber ? `-R${document.revisionNumber}` : ''
    }`;
  }

  if (docType === DocumentTemplateType.AsBuilt) {
    if (document.documentTemplate?.name === DocumentTemplateType.AsBuiltPackages) {
      return `As-Built Package — ${document.referenceNumber}`;
    }
    return `As-Built — ${document.sheetNumber} ${document.title || ''}`;
  }

  if (docType === DocumentTemplateType.PunchList) {
    return `Punch List — ${document.description}${
      document.revisionNumber ? ' -R' + document.revisionNumber : ''
    }`;
  }

  return `${documentTypeToReadable[docType]} #${
    document.referenceNumber || document.documentNumber
  }${document.revisionNumber ? '-R' + document.revisionNumber : ''}`;
};

export const isPublicPage = () => {
  const key = window.location.pathname.split('/').slice(2, 3).join('/');
  return (
    key === 'public' ||
    key === 'register' ||
    (key === 'pdf' && localStorage.getItem('publicAccessKey') !== null)
  );
};

export const isLoginPage = () => {
  const key = window.location.pathname.split('/').slice(2, 3).join('/');
  return key === 'login';
};

export const isPdfViewer = () =>
  window.location.pathname.split('/').slice(2, 3).join('/') === 'pdf';

let publicPageRedirect: null | string = null;
export const getPublicPageRedirect = () => {
  return publicPageRedirect;
};
export const setPublicPageRedirect = (str: string | null) => {
  publicPageRedirect = str;
};

export const getUserFriendlyDocumentTemplateNameSingular = (
  documentTemplateType: DocumentTemplateType,
) => {
  switch (documentTemplateType) {
    case DocumentTemplateType.BidAdvertisement:
      return 'Bid Advertisement';
    case DocumentTemplateType.BidTabulation:
      return 'Bid Tabulation';
    case DocumentTemplateType.InformationalItems:
      return 'Informational Item';
    case DocumentTemplateType.ProjectManual:
      return 'Project Manual';
    case DocumentTemplateType.SubstitutionRequests:
      return 'Substitution Request';
    case DocumentTemplateType.RegulatoryApprovals:
      return 'Regulatory Approval';
    case DocumentTemplateType.BidderRfIs:
      return 'Bidder RFI';
    case DocumentTemplateType.Addenda:
      return 'Addendum';
    case DocumentTemplateType.BidDrawings:
      return 'Bid Drawing';
    case DocumentTemplateType.AsiDocuments:
      return 'ASI';
    case DocumentTemplateType.ChangeOrders:
      return 'Change Order';
    case DocumentTemplateType.ConstructionChangeDirectives:
      return 'Construction Change Directive';
    case DocumentTemplateType.ContractorDailyLogs:
      return 'Contractor Daily Log';
    case DocumentTemplateType.FieldReports:
      return 'Field Report';
    case DocumentTemplateType.MeetingMinutes:
      return 'Meeting Minute';
    case DocumentTemplateType.MiscellaneousDocuments:
      return 'Miscellaneous';
    case DocumentTemplateType.PayApplications:
      return 'Pay Application';
    case DocumentTemplateType.PotentialChangeOrders:
      return 'Potential Change Order';
    case DocumentTemplateType.RequestsForChange:
      return 'Request For Change';
    case DocumentTemplateType.RequestsForInformation:
      return 'Request For Information';
    case DocumentTemplateType.Schedules:
      return 'Schedule';
    case DocumentTemplateType.Submittals:
      return 'Submittal';
    case DocumentTemplateType.Testing:
      return 'Testing';
    case DocumentTemplateType.WorkChangeProposalRequests:
      return 'Work Change Proposal Request';
    case DocumentTemplateType.AsBuilt:
      return 'As-Built';
    case DocumentTemplateType.CertificateOfSubstantialCompletion:
      return 'Certificate Of Substantial Completion';
    case DocumentTemplateType.ClearLp:
      return 'Clear LP';
    case DocumentTemplateType.CloseoutSubmittals:
      return 'Closeout Submittal';
    case DocumentTemplateType.OperationsAndMaintenanceData:
      return 'Operations And Maintenance Data';
    case DocumentTemplateType.PunchList:
      return 'Punch List';
    case DocumentTemplateType.WarrantyItems:
      return 'Warranty Item';
    case DocumentTemplateType.Drawings:
      return 'Drawing';
    case DocumentTemplateType.Specifications:
      return 'Specification';
    case DocumentTemplateType.Task:
      return 'Item';
    case DocumentTemplateType.AdditionalReview:
      return 'Consultant Review';
    case DocumentTemplateType.SubmittalPackages:
      return 'Submittal Package';
    case DocumentTemplateType.CloseoutSubmittalPackages:
      return 'Closeout Submittal Package';
    case DocumentTemplateType.PlanholderList:
      return 'Planholder List';
    case DocumentTemplateType.AsBuiltPackages:
      return 'As-Built Package';
    default:
      console.warn(`Unknown document type: ${documentTemplateType}`);
      return 'Unknown Document Type';
  }
};

export const getUserFriendlyDocumentTemplateNamePlural = (
  documentTemplateType: DocumentTemplateType,
) => {
  switch (documentTemplateType) {
    case DocumentTemplateType.BidAdvertisement:
      return 'Bid Advertisements';
    case DocumentTemplateType.BidTabulation:
      return 'Bid Tabulations';
    case DocumentTemplateType.InformationalItems:
      return 'Informational Items';
    case DocumentTemplateType.ProjectManual:
      return 'Project Manuals';
    case DocumentTemplateType.SubstitutionRequests:
      return 'Substitution Requests';
    case DocumentTemplateType.RegulatoryApprovals:
      return 'Regulatory Approvals';
    case DocumentTemplateType.BidderRfIs:
      return 'Bidder RFIs';
    case DocumentTemplateType.Addenda:
      return 'Addendas';
    case DocumentTemplateType.BidDrawings:
      return 'Bid Drawings';
    case DocumentTemplateType.AsiDocuments:
      return 'ASIs';
    case DocumentTemplateType.ChangeOrders:
      return 'Change Orders';
    case DocumentTemplateType.ConstructionChangeDirectives:
      return 'Construction Change Directives';
    case DocumentTemplateType.ContractorDailyLogs:
      return 'Contractor Daily Logs';
    case DocumentTemplateType.FieldReports:
      return 'Field Reports';
    case DocumentTemplateType.MeetingMinutes:
      return 'Meeting Minutes';
    case DocumentTemplateType.MiscellaneousDocuments:
      return 'Miscellaneous';
    case DocumentTemplateType.PayApplications:
      return 'Pay Applications';
    case DocumentTemplateType.PotentialChangeOrders:
      return 'Potential Change Orders';
    case DocumentTemplateType.RequestsForChange:
      return 'Requests For Change';
    case DocumentTemplateType.RequestsForInformation:
      return 'Requests For Information';
    case DocumentTemplateType.Schedules:
      return 'Schedules';
    case DocumentTemplateType.Submittals:
      return 'Submittals';
    case DocumentTemplateType.Testing:
      return 'Testing';
    case DocumentTemplateType.WorkChangeProposalRequests:
      return 'Work Change Proposal Requests';
    case DocumentTemplateType.AsBuilt:
      return 'As Builts';
    case DocumentTemplateType.CertificateOfSubstantialCompletion:
      return 'Certificates Of Substantial Completion';
    case DocumentTemplateType.ClearLp:
      return 'Clear LPs';
    case DocumentTemplateType.CloseoutSubmittals:
      return 'Closeout Submittals';
    case DocumentTemplateType.OperationsAndMaintenanceData:
      return 'Operations And Maintenance Data';
    case DocumentTemplateType.PunchList:
      return 'Punch Lists';
    case DocumentTemplateType.WarrantyItems:
      return 'Warranty Items';
    case DocumentTemplateType.Drawings:
      return 'Drawings';
    case DocumentTemplateType.Specifications:
      return 'Specifications';
    case DocumentTemplateType.Task:
      return 'Tasks';
    case DocumentTemplateType.DesignPackages:
      return 'Design Packages';
    case DocumentTemplateType.AdditionalReview:
      return 'Consultant Reviews';
    case DocumentTemplateType.SubmittalPackages:
      return 'Submittal Packages';
    case DocumentTemplateType.CloseoutSubmittalPackages:
      return 'Closeout Submittal Packages';
    case DocumentTemplateType.PlanholderList:
      return 'Planholder List';
    default:
      console.warn(`Unknown document type: ${documentTemplateType}`);
      return 'Unknown Document Type';
  }
};

export const getSubmittalTitle = (submittal: INumberedDocumentView, withTitle = true) => {
  let title = submittal.submittalSection || '';
  if (submittal.submittalParagraphNumber) title += `-${submittal.submittalParagraphNumber}`;
  if (submittal.submittalSubparagraphNumber) title += `-${submittal.submittalSubparagraphNumber}`;
  if (submittal.revisionNumber) title += `-R${submittal.revisionNumber}`;
  if (withTitle) {
    if (submittal.submittalSectionDescription) title += ` ${submittal.submittalSectionDescription}`;
    if (submittal.title) title += ` — ${submittal.title}`;
  }
  return title;
};

export const isLocal = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  return !!apiUrl && apiUrl.includes('localhost');
};

export const isDev = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  return !!apiUrl && (apiUrl.includes('localhost') || apiUrl.includes('dev'));
};

export const generateProcoreLoginLink = () => {
  return `https://login.procore.com/oauth/authorize?response_type=code&client_id=${
    process.env.REACT_APP_PROCORE_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(process.env.REACT_APP_PROCORE_CALLBACK_URL!)}`;
};

export const getTomorrowDate = () => {
  const date = dayjs().toDate();
  date.setDate(date.getDate() + 1);
  return date;
};

export function onlyUniqueByProperty<T>(value: T, index: number, self: T[], property: keyof T) {
  return self.findIndex((item) => item[property] === value[property]) === index;
}

export const isSimpleWorkflowDoc = (docType: DocumentTemplateType | undefined | null) => {
  if (!docType) return false;
  switch (docType) {
    case DocumentTemplateType.SubstitutionRequests:
    case DocumentTemplateType.BidderRfIs:
    case DocumentTemplateType.PayApplications:
    case DocumentTemplateType.WorkChangeProposalRequests:
    case DocumentTemplateType.PunchList:
    case DocumentTemplateType.PotentialChangeOrders:
    case DocumentTemplateType.RequestsForChange:
    case DocumentTemplateType.RequestsForInformation:
    case DocumentTemplateType.AsBuilt:
      return true;
    default:
      return false;
  }
};

export const isPdf = (name?: string) =>
  !!name && name.slice(name.length - 3).toLowerCase() === 'pdf';

export const generateUniqueId = () => {
  return v4();
};

export const removeNewlines = (text?: string): string | undefined =>
  text?.replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ');

export function sortLastNamesDesc(a = '', b = '') {
  const name1 = a.trim().split(' ').reverse().join(' ');
  const name2 = b.trim().split(' ').reverse().join(' ');
  return name1.localeCompare(name2);
}

export const nodeIsSubfolder = (node?: FileNode) =>
  !!node && (node.fullKey.match(/\//g) || []).length > 2;

export const canViewDesign = (
  //Required params
  productPackage?: IProductPackage | null,
  security?: ISecurityGroup | null,
  //Optional params for determining if admin
  user?: IUser,
  currentProject?: Project,
) => {
  //If admin
  if (
    user &&
    currentProject &&
    (user.adminOfSubscriberId === currentProject.subscriberId || user.isSiteAdmin)
  ) {
    return true;
  }
  if (security === null || !productPackage?.canViewDesign) {
    return false;
  } else if (security === undefined) {
    return productPackage.canViewDesign;
  } else {
    const { designTabPermission } = security;
    return (
      designTabPermission !== null &&
      designTabPermission !== undefined &&
      designTabPermission >= SecurityPermissionLevel.NUMBER_0 &&
      productPackage.canViewDesign
    );
  }
};

export const canViewBidding = (productPackage?: IProductPackage | null) => {
  if (!productPackage?.canViewBidding) return false;
  return productPackage.canViewBidding;
};

export const canViewConstructionAndCloseout = (productPackage?: IProductPackage | null) => {
  if (!productPackage?.canViewConstructionAndCloseout) return false;
  return productPackage.canViewConstructionAndCloseout;
};

export const getFilteredUsersFromUserGroup = (
  type: ManagePermissionsDialogType,
  group?: IUserGroup | null,
) => {
  return getUsersFromUserGroup(group).filter((user) => hasPermissionToView(type, user));
};

type ConsultantReviewSummaryRow = {
  id: string;
  name: string;
  workflowStatus: WorkflowStatusType;
  companyName: string;
  dueDate: string;
};

export const getCurrentlyResponsibleUsers = (
  document?: INumberedDocumentView | null,
): { id?: string | null; name: string }[] => {
  if (!document || document.actionTaken === ActionTakenType.Withdrawn)
    return [{ name: 'Not Assigned' }];
  const {
    workflowStatus,
    generalContractorUserId,
    generalContractorUser,
    architectUserId,
    architectUser,
    consultantSummary,
  } = document;

  const generalContractor = {
    id: generalContractorUserId,
    name: generalContractorUser?.name || 'Responsible General Contractor',
  };
  const architect = {
    id: architectUserId,
    name: architectUser?.name || 'Responsible Architect',
  };

  switch (workflowStatus) {
    case WorkflowStatusType.Initial:
    case WorkflowStatusType.ReadyForSubmissionToArchitect:
      if (document.isWorkflowInverted) {
        return [architect];
      }
      return [generalContractor];

    case WorkflowStatusType.SubmittedForReview:
    case WorkflowStatusType.UnderReview:
    case WorkflowStatusType.ArchitectUploaded:
      if (
        consultantSummary?.numConsultantReviewsResolved &&
        consultantSummary.numConsultantReviewsResolved > 0
      ) {
        if (document.isWorkflowInverted) {
          return [generalContractor];
        }
        return [architect];
      }
      if (
        (consultantSummary?.numConsultantReviewsUnderReview &&
          consultantSummary.numConsultantReviewsUnderReview > 0) ||
        (consultantSummary?.numConsultantReviewsSubmittedForReview &&
          consultantSummary.numConsultantReviewsSubmittedForReview > 0)
      ) {
        return _.uniq(
          (consultantSummary.summaryByReview as ConsultantReviewSummaryRow[])
            .filter(
              ({ workflowStatus }) =>
                workflowStatus !== WorkflowStatusType.Resolved &&
                workflowStatus !== WorkflowStatusType.Resubmitted &&
                workflowStatus !== WorkflowStatusType.Accepted,
            )
            .map((row) => {
              if (row.workflowStatus === WorkflowStatusType.ReviewComplete) {
                if (document.isWorkflowInverted) {
                  return generalContractor;
                }
                return architect;
              } else return { id: row.id, name: row.name };
            }),
        );
      }

      if (document.isWorkflowInverted) {
        return [generalContractor];
      }
      return [architect];

    default:
      break;
  }
  return [{ name: 'Not Assigned' }];
};

export const getCurrentlyResponsibleCompany = (document?: INumberedDocumentView | null): string => {
  if (!document || document.actionTaken === ActionTakenType.Withdrawn) return '';
  const { workflowStatus, generalContractorUser, architectUser, consultantSummary } = document;

  const generalContractor = generalContractorUser?.company?.name || "General Contractor's Company";
  const architect = architectUser?.company?.name || "Architect's Company";

  switch (workflowStatus) {
    case WorkflowStatusType.Initial:
    case WorkflowStatusType.ReadyForSubmissionToArchitect:
      if (document.isWorkflowInverted) {
        return architect;
      }
      return generalContractor;

    case WorkflowStatusType.SubmittedForReview:
    case WorkflowStatusType.UnderReview:
    case WorkflowStatusType.ArchitectUploaded:
      if (
        consultantSummary?.numConsultantReviewsResolved &&
        consultantSummary.numConsultantReviewsResolved > 0
      ) {
        if (document.isWorkflowInverted) {
          return generalContractor;
        }
        return architect;
      }
      if (
        (consultantSummary?.numConsultantReviewsUnderReview &&
          consultantSummary.numConsultantReviewsUnderReview > 0) ||
        (consultantSummary?.numConsultantReviewsSubmittedForReview &&
          consultantSummary.numConsultantReviewsSubmittedForReview > 0)
      ) {
        return (
          _.nth(consultantSummary.summaryByReview as ConsultantReviewSummaryRow[], 0)
            ?.companyName || "Consultant's Company"
        );
      }

      if (document.isWorkflowInverted) {
        return generalContractor;
      }
      return architect;

    default:
      break;
  }
  return '';
};

export const getPageTitle = (
  docType: DocumentTemplateType,
  document: INumberedDocumentView | null | undefined,
  bid?: IBid | null,
) => {
  if (bid) {
    return `Bid Submission #${bid.bidNumber}`;
  }
  if (!document) return 'N/A';
  if (docType === DocumentTemplateType.PotentialChangeOrders)
    return `Potential Change Order #${document.referenceNumber}${
      document?.revisionNumber ? `-R${document.revisionNumber}` : ''
    }`;
  if (docType === DocumentTemplateType.WorkChangeProposalRequests)
    return `Work Change Proposal Request #${document.referenceNumber}`;
  if (docType === DocumentTemplateType.FieldReports)
    return `Field Report ${
      document.dateOfObservation
        ? `#${parseDate(document.dateOfObservation).format('YYYY-MM-DD')}`
        : ''
    }`;
  if (
    docType === DocumentTemplateType.Submittals ||
    docType === DocumentTemplateType.CloseoutSubmittals
  ) {
    let publicPageExtension = '';
    if (isPublicPage()) {
      if (docType === DocumentTemplateType.Submittals) {
        if (document.documentTemplate?.name === DocumentTemplateType.SubmittalPackages)
          publicPageExtension = 'Submittal Package ';
        else publicPageExtension = 'Submittal ';
      }
      if (docType === DocumentTemplateType.CloseoutSubmittals) {
        if (document.documentTemplate?.name === DocumentTemplateType.CloseoutSubmittalPackages)
          publicPageExtension = 'Closeout Submittal Package ';
        else publicPageExtension = 'Closeout Submittal ';
      }
      return `${publicPageExtension}${getSubmittalTitle(document)}`;
    }
    if (
      document.documentTemplate?.name === DocumentTemplateType.SubmittalPackages ||
      document.documentTemplate?.name === DocumentTemplateType.CloseoutSubmittalPackages
    ) {
      return `${getUserFriendlyDocumentTemplateNameSingular(
        (document?.documentTemplate?.name as DocumentTemplateType) || docType || undefined,
      )} - ${document.referenceNumber || document.documentNumber!.toString()}`;
    }
    return `${getUserFriendlyDocumentTemplateNameSingular(
      (document?.documentTemplate?.name as DocumentTemplateType) || docType || undefined,
    )} ${getSubmittalTitle(document)}`;
  }
  if (docType === DocumentTemplateType.Drawings) {
    return `${documentTypeToReadable[docType]} #${
      document?.sheetNumber || document?.documentNumber || 'N/A'
    }`;
  }
  if (
    docType === DocumentTemplateType.AsBuilt ||
    docType === DocumentTemplateType.AsBuiltPackages
  ) {
    if (
      document.documentTemplate?.name === DocumentTemplateType.AsBuiltPackages ||
      docType === DocumentTemplateType.AsBuiltPackages
    ) {
      return `As-Built Package — ${document.referenceNumber}`;
    }
    return `As-Built — ${document.sheetNumber} ${document.title || ''}`;
  }
  if (
    docType === DocumentTemplateType.RequestsForInformation ||
    docType === DocumentTemplateType.Task ||
    docType === DocumentTemplateType.DesignPackages
  ) {
    return `${documentTypeToReadable[docType]} #${document?.referenceNumber}${
      document?.revisionNumber ? `-R${document.revisionNumber}` : ''
    }${document?.title ? ` — ${document.title}` : ''}`;
  }
  if (
    docType === DocumentTemplateType.AsiDocuments ||
    docType === DocumentTemplateType.RequestsForChange
  ) {
    return `${documentTypeToReadable[docType]} #${document?.referenceNumber}`;
  }
  if (docType === DocumentTemplateType.Addenda) {
    return `${documentTypeToReadable[docType]} ${
      document?.documentNumber
        ? `#${
            document.documentNumber.toString().length === 1
              ? `0${document.documentNumber}`
              : document.documentNumber
          }`
        : ''
    }`;
  }
  if (docType === DocumentTemplateType.Specifications) {
    return `${document.submittalSection} ${document.submittalSectionDescription || ''}`;
  }

  if (docType === DocumentTemplateType.MiscellaneousDocuments) {
    return `${documentTypeToReadable[docType]} #${document.documentNumber}${
      document.title ? ' — ' + document.title : ''
    }`;
  }

  if (docType === DocumentTemplateType.WarrantyItems) {
    return `Warranty Item #${document.documentNumber}${
      document.revisionNumber ? '-R' + document.revisionNumber : ''
    }`;
  }

  return `${documentTypeToReadable[docType]} #${
    document.referenceNumber || document.documentNumber
  }${document.revisionNumber ? '-R' + document.revisionNumber : ''}`;
};

export const copyToClipboard = async (text: string) => {
  if (navigator.clipboard !== undefined) await navigator.clipboard.writeText(text);
  else {
    console.log('navigator.clipboard is undefined, trying fallback...');
    if (window.document.queryCommandSupported && window.document.queryCommandSupported('copy')) {
      const textArea = window.document.createElement('textarea');

      // Place in the top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = '0';

      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';

      // Avoid flash of the white box if rendered for any reason.
      textArea.style.background = 'transparent';

      textArea.value = text;

      window.document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      window.document.execCommand('copy');

      window.document.body.removeChild(textArea);
    }
  }
};

export const getParentFromFullKey = (fullKey: string) => {
  const keys = fullKey.split('/').filter((k) => k);
  if (keys.length === 1) return '/';
  keys.pop();
  return keys[keys.length - 1];
};

export const areFilesInSameParentFolder = (files: FileNode[]) => {
  if (files.length <= 1) return true;
  const parentKeys = files.map((f) => getParentFromFullKey(f.fullKey));
  return parentKeys.every((key, index, array) => array[0] === key);
};

export const sleep = (ms: number) => {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, ms);
  });
};

const VERIFY_WAIT_TIME_MILLISECONDS = 30000;
const VERIFY_WAIT_TIME_MILLISECONDS_BLUEBEAM = 300000;

export const waitForFileToBeVerified = async (file: IFile, isBluebeam = false) => {
  if (file.isVerified) return true;
  const timeoutLength = isBluebeam
    ? VERIFY_WAIT_TIME_MILLISECONDS_BLUEBEAM
    : VERIFY_WAIT_TIME_MILLISECONDS;

  // Give the file a chance to be verified first
  await sleep(1000);

  const startTime = Date.now();
  return new Promise<boolean>(async (resolve) => {
    while (Date.now() < startTime + timeoutLength) {
      const { isVerified } = await getFileById(file.id);
      if (isVerified) {
        resolve(true);
        break;
      } else await sleep(5000);
    }
    resolve(false);
  });
};

export const waitForFileToBeVerifiedByUrl = async (url: string) => {
  const timeoutLength = VERIFY_WAIT_TIME_MILLISECONDS;

  const startTime = Date.now();
  return new Promise<boolean>(async (resolve) => {
    while (Date.now() < startTime + timeoutLength) {
      const isVerified = await verifyFileByUrl(url);
      if (isVerified) {
        resolve(true);
        break;
      } else await sleep(5000);
    }
    resolve(false);
  });
};

export const getUserFriendlyStringForDesignActionTakenType = (
  action: DesignActionTakenType,
  isFolder = false,
) => {
  switch (action) {
    case DesignActionTakenType.UploadedFile:
      return 'uploaded this file';

    case DesignActionTakenType.CreatedFolder:
      return 'created this folder';

    case DesignActionTakenType.DownloadedFile:
      return 'downloaded this file';

    case DesignActionTakenType.ViewedFile:
      return 'viewed this file';

    case DesignActionTakenType.DeletedNode:
      return `deleted this ${isFolder ? 'folder' : 'file'}`;

    case DesignActionTakenType.SharedNode:
      // return `shared this ${isFolder ? 'folder' : 'file'}`;
      return 'shared with';

    case DesignActionTakenType.SentDownloadLink:
      return 'sent a download link to';

    case DesignActionTakenType.ModifiedNode:
      return `renamed this ${isFolder ? 'folder' : 'file'}`;

    case DesignActionTakenType.AccessNotification:
      return 'notified';

    case DesignActionTakenType.SentPublicFolderAccessLink:
      return 'sent a public access link to';

    default:
      break;
  }
};

export const getFileOrFolderNameFromFullKey = (fullKey: string) => {
  const fullKeySplit = fullKey.split('/');
  const fileName = fullKeySplit.pop();
  if (fileName) return fileName;
  return fullKeySplit.pop();
};

export const computeOptimalImageSize = (
  initialWidth: number,
  initialHeight: number,
  maxWidth: number,
  maxHeight: number,
  zoomFactor = 1.5,
) => {
  let newWidth = initialWidth;
  let newHeight = initialHeight;
  let zoomCount = 0;

  while (
    newWidth * zoomFactor <= maxWidth &&
    newHeight * zoomFactor <= maxHeight &&
    zoomCount < 5
  ) {
    newWidth = newWidth * zoomFactor;
    newHeight = newHeight * zoomFactor;
    zoomCount += 1;
  }

  return { width: newWidth, height: newHeight };
};

export const getDocumentRevisions = (document: INumberedDocumentView) => {
  if (!documentIsRevision(document)) return [];
  if (!document.associatedDocumentsList) return [];
  const revisions = document.associatedDocumentsList
    .filter(({ associatedDocument }) => {
      if (!associatedDocument) return false;
      if (associatedDocument.documentNumber !== document.documentNumber) return false;
      if (document.revisionNumber === 1) return !associatedDocument.revisionNumber;
      return associatedDocument.revisionNumber === document.revisionNumber! - 1;
    })
    .map(({ associatedDocument }) => associatedDocument) as INumberedDocumentView[];
  return revisions.sort((a, b) => descendingComparator(a, b, 'createdOn')) || [];
};

export const getUserFriendlyParserJobStatusType = (status: ExpandedJobStatusType) => {
  switch (status) {
    case ExpandedJobStatusType.CreatingJob:
    case ExpandedJobStatusType.Pending:
      return 'Loading';

    case ExpandedJobStatusType.Initiated:
      return 'Parsing';

    case ExpandedJobStatusType.Complete:
      return 'Success';

    case ExpandedJobStatusType.JobCreationFailed:
    case ExpandedJobStatusType.Canceled:
      return 'Failed';

    default:
      return 'Loading';
  }
};

export const getAsBuiltStatus = (document: INumberedDocumentView) => {
  if (document.workflowStatus === WorkflowStatusType.Completed) {
    if (document.actionTaken) return getActionTakenText(document.actionTaken);
    return 'Completed';
  }
  return document.workflowStatus ? statusToGeneralString[document.workflowStatus] : 'N/A';
};

export const parseDate = (date: string) => {
  const dateJs = parseDateHelper(date);
  if (dateJs.utc(false).format('HH:mm:ss.SSS') === '00:00:00.000') {
    return dateJs.utc(false);
  }
  return dateJs;
};

const parseDateHelper = (date: string) => {
  let returnDate = dayjs(date);
  if (returnDate.isValid()) return returnDate;

  const dateParsed = date.replaceAll('-', '/');
  const split = dateParsed.split('.');
  if (!split[1]) {
    returnDate = dayjs(dateParsed);
    if (returnDate.isValid()) return returnDate;
  }

  return dayjs(`${split[0]}+00`);
};

export const getDocumentTitleForAssociations = (
  document: INumberedDocumentView,
  type: DocumentTemplateType,
  inDialog = true,
): string => {
  let title = '';
  switch (type) {
    case DocumentTemplateType.Specifications:
      if (document.submittalSection && document.submittalSectionDescription)
        title = `${addSpacing(document.submittalSection!)} - ${
          document.submittalSectionDescription
        }`;
      else title = document.title!;
      break;

    case DocumentTemplateType.Submittals:
    case DocumentTemplateType.CloseoutSubmittals:
      title = document.submittalSection
        ? getSubmittalTitle(document, false) +
          ` ${document.submittalSectionDescription} - ${document.title}`
        : document.title!;
      break;

    case DocumentTemplateType.Drawings:
    case DocumentTemplateType.AsBuilt:
      title = `${document.sheetNumber || 'N/A'} - ${document.title}`;
      break;

    case DocumentTemplateType.AsBuiltPackages:
    case DocumentTemplateType.SubmittalPackages:
    case DocumentTemplateType.CloseoutSubmittalPackages:
      title = `${document.referenceNumber} - ${document.title}`;
      break;

    default:
      return getTitle(type, document);
  }

  if (inDialog) return title;
  return `${getUserFriendlyDocumentTemplateNameSingular(type)} ${title}`;
};

export type DocumentFileTreeDocument = {
  id: string;
  title: string;
  isPackage?: boolean;
  checked: boolean;
  files?: DocumentFileTreeDocument[];
};

export type DocumentFileTree = {
  type: string;
  documents: DocumentFileTreeDocument[];
};

const documentColumnsForAssociations: (keyof INumberedDocumentView)[] = [
  'id',
  'documentTemplateId',
  'submittalSection',
  'submittalSectionDescription',
  'title',
  'sheetNumber',
  'referenceNumber',
  'documentNumber',
  'revisionNumber',
  'dateOfObservation',
  'description',
];

export const getDocumentTreesForAssociations = async (
  projectId: string,
  documentIdsToRemove?: string[],
) => {
  const newDocuments: DocumentFileTree[] = [];
  const templates = await getTemplateIds();
  let allDocuments = await getDocumentsByProjectId(projectId, documentColumnsForAssociations);

  if (documentIdsToRemove) {
    allDocuments = allDocuments.filter((d) => !documentIdsToRemove.includes(d.id));
  }

  allDocuments.forEach((document) => {
    const trueDocumentType = templates[document.documentTemplateId] as DocumentTemplateType;
    let documentType = trueDocumentType;

    if (
      trueDocumentType === DocumentTemplateType.Task ||
      trueDocumentType === DocumentTemplateType.DesignPackages ||
      trueDocumentType === DocumentTemplateType.AdditionalReview ||
      trueDocumentType === DocumentTemplateType.BidDrawings ||
      trueDocumentType === DocumentTemplateType.ProjectManual ||
      trueDocumentType === DocumentTemplateType.Addenda ||
      trueDocumentType === DocumentTemplateType.InformationalItems ||
      trueDocumentType === DocumentTemplateType.SubstitutionRequests ||
      trueDocumentType === DocumentTemplateType.BidderRfIs ||
      trueDocumentType === DocumentTemplateType.PlanholderList ||
      trueDocumentType === DocumentTemplateType.BidTabulation ||
      trueDocumentType === DocumentTemplateType.RegulatoryApprovals ||
      trueDocumentType === DocumentTemplateType.BidAdvertisement
    )
      return null;

    if (trueDocumentType === DocumentTemplateType.SubmittalPackages)
      documentType = DocumentTemplateType.Submittals;
    else if (trueDocumentType === DocumentTemplateType.CloseoutSubmittalPackages)
      documentType = DocumentTemplateType.CloseoutSubmittals;
    else if (trueDocumentType === DocumentTemplateType.AsBuiltPackages)
      documentType = DocumentTemplateType.AsBuilt;

    const index = newDocuments.findIndex((doc) => doc.type === documentType);
    if (index !== -1)
      newDocuments[index].documents.push({
        id: document.id,
        title: getDocumentTitleForAssociations(document, trueDocumentType),
        isPackage: documentType !== trueDocumentType,
        checked: false,
      });
    else
      newDocuments.push({
        type: documentType,
        documents: [
          {
            id: document.id,
            title: getDocumentTitleForAssociations(document, trueDocumentType),
            isPackage: documentType !== trueDocumentType,
            checked: false,
          },
        ],
      });
  });

  return newDocuments
    .filter((tree) => tree.documents.length > 0)
    .sort((a, b) => ascendingComparator(a, b, 'type'));
};

export const shouldDisplayUser = (user: IUser, isAdminList: boolean) => {
  if (isAdminList) {
    return true;
  }
  return !user.isDisabled && (!!user.isRegistered || user.companyId === null);
};

export const getProjectUsers = (
  project: { projectUserList?: IProjectUser[] } | undefined | null,
  isAdminList: boolean,
): IProjectUser[] => {
  return (
    project?.projectUserList?.filter((pu) => pu.user && shouldDisplayUser(pu.user, isAdminList)) ||
    []
  );
};

// from https://stackoverflow.com/a/68469827
export function inflateObject<T>(base64str: string) {
  const strData = atob(base64str);

  // Convert binary string to character-number array
  const charData = strData.split('').map((x) => {
    return x.charCodeAt(0);
  });

  // Turn number array into byte-array
  const binData = new Uint8Array(charData);

  return JSON.parse(pako.inflate(binData, { to: 'string' })) as T;
}

export const getUserFriendlyDocumentPriority = (priority: DocumentPriorityType) => {
  switch (priority) {
    case DocumentPriorityType.Low:
      return 'Low';
    case DocumentPriorityType.Medium:
      return 'Medium';
    case DocumentPriorityType.High:
      return 'High';
    case DocumentPriorityType.ForInformationOnly:
      return 'For Information Only';
    default:
      return priority;
  }
};

export const getUserFriendlyTaskStatusFromWorkflowStatus = (
  status: WorkflowStatusType | null | undefined,
) => {
  switch (status) {
    case WorkflowStatusType.SubmittedForReview:
      return 'Not Started';
    case WorkflowStatusType.ArchitectUploaded:
    case WorkflowStatusType.UnderReview:
      return 'In Progress';
    case WorkflowStatusType.ReviewComplete:
      return 'Completed';
    case WorkflowStatusType.Resolved:
      return 'Accepted';
    case WorkflowStatusType.Resubmitted:
      return 'Resubmitted';
    default:
      return getUserFacingWorkflowStatus(status);
  }
};

export const getUserFriendlyCost = (cost: number | null) => {
  if (cost == null) return null;
  const dollars = cost / 100;
  return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export const getUserFriendlyNumber = (num: number | null) => {
  if (num == null) return null;
  return num.toString();
};

export const getReferenceNumberLabelFromDocumentType = (
  documentType: DocumentTemplateType | null,
) => {
  switch (documentType) {
    case DocumentTemplateType.Task:
      return 'Task #';

    case DocumentTemplateType.AsiDocuments:
      return 'ASI #';

    case DocumentTemplateType.RequestsForInformation:
      return 'RFI #';

    case DocumentTemplateType.ChangeOrders:
      return 'Change Order #';

    case DocumentTemplateType.ConstructionChangeDirectives:
      return 'CCD #';

    case DocumentTemplateType.PotentialChangeOrders:
      return 'PCO #';

    case DocumentTemplateType.RequestsForChange:
      return 'RFC #';

    case DocumentTemplateType.WorkChangeProposalRequests:
      return 'WCPR #';

    case DocumentTemplateType.PayApplications:
      return 'Pay App #';

    case DocumentTemplateType.Testing:
      return 'Testing #';

    case DocumentTemplateType.MiscellaneousDocuments:
      return 'Misc. Document #';

    case DocumentTemplateType.Schedules:
      return 'Schedule #';

    case DocumentTemplateType.Addenda:
      return 'Addendum #';

    case DocumentTemplateType.InformationalItems:
      return 'Informational Item #';

    case DocumentTemplateType.WarrantyItems:
      return 'Reference #';

    default:
      return 'Document #';
  }
};

export const getUserFriendlyUser = (user: IUser | null) => {
  if (user == null) return null;
  if (user.company) return `${user.name} from ${user.company.name}`;
  return user.name;
};

const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export function stringIsUuid(str: string) {
  return uuidRegex.test(str);
}

export const getUserFriendlyDocumentFields = (
  patch: Partial<INumberedDocumentView> & { contactPhone?: string | null },
  documentType: DocumentTemplateType | null,
) => {
  const userFriendlyKeyValues: { key: string; value: string | null }[] = [];
  if (patch.contactPhone !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('contactPhone'),
      value: patch.contactPhone,
    });
  if (patch.documentTemplateId !== undefined)
    userFriendlyKeyValues.push({
      key: 'Document Type',
      value: patch.documentTemplateId,
    });
  if (patch.documentTemplate !== undefined) {
    userFriendlyKeyValues.push({
      key: 'Document Type',
      value:
        patch.documentTemplate !== null
          ? getUserFriendlyDocumentTemplateNameSingular(
              patch.documentTemplate.name as DocumentTemplateType,
            )
          : null,
    });
  }
  if (patch.projectId !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('projectId'), value: patch.projectId });
  if (patch.isHidden !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('isHidden'),
      value: patch.isHidden ? 'True' : 'False',
    });
  if (patch.assignedToUserId !== undefined)
    userFriendlyKeyValues.push({
      key: 'Currently Responsible',
      value: patch.assignedToUserId,
    });
  if (patch.workflowStatus !== undefined)
    userFriendlyKeyValues.push({
      key: 'Current Status',
      value: patch.workflowStatus,
    });
  if (patch.architectUserId !== undefined)
    userFriendlyKeyValues.push({
      key: 'Responsible Architect',
      value: patch.architectUserId,
    });
  if (patch.architectUser !== undefined)
    userFriendlyKeyValues.push({
      key: 'Architect',
      value: getUserFriendlyUser(patch.architectUser),
    });
  if (patch.generalContractorUserId !== undefined)
    userFriendlyKeyValues.push({
      key: 'Responsible General Contractor',
      value: patch.generalContractorUserId,
    });
  if (patch.generalContractorUser !== undefined)
    userFriendlyKeyValues.push({
      key: 'General Contractor',
      value: getUserFriendlyUser(patch.generalContractorUser),
    });
  if (patch.submittalSection !== undefined)
    userFriendlyKeyValues.push({
      key: 'Specification Section',
      value: patch.submittalSection,
    });
  if (patch.submittalParagraphNumber !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('submittalParagraphNumber'),
      value: patch.submittalParagraphNumber,
    });
  if (patch.submittalSubparagraphNumber !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('submittalSubparagraphNumber'),
      value: patch.submittalSubparagraphNumber,
    });
  if (patch.submittalSectionDescription !== undefined)
    userFriendlyKeyValues.push({
      key: 'Section Description',
      value: patch.submittalSectionDescription,
    });
  if (patch.title !== undefined)
    userFriendlyKeyValues.push({
      key: documentType === DocumentTemplateType.Submittals ? 'Description' : _.startCase('title'),
      value: patch.title,
    });
  if (patch.description !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('description'), value: patch.description });
  if (patch.referenceNumber !== undefined)
    userFriendlyKeyValues.push({
      key: getReferenceNumberLabelFromDocumentType(documentType),
      value: patch.referenceNumber,
    });
  if (patch.documentNumber !== undefined)
    userFriendlyKeyValues.push({
      key: getReferenceNumberLabelFromDocumentType(documentType),
      value:
        (documentType === DocumentTemplateType.Addenda ||
        documentType === DocumentTemplateType.InformationalItems
          ? patch.documentNumber?.toString().padStart(2, '0')
          : patch.documentNumber?.toString()) || null,
    });
  if (patch.cost !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('cost'),
      value: getUserFriendlyCost(patch.cost),
    });
  if (patch.approvedCost !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('approvedCost'),
      value: getUserFriendlyCost(patch.approvedCost),
    });
  if (patch.requestedAdditionalDays !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('requestedAdditionalDays'),
      value: getUserFriendlyNumber(patch.requestedAdditionalDays),
    });
  if (patch.impacts !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('impacts'), value: patch.impacts });
  if (patch.proposedAction !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('proposedAction'),
      value: patch.proposedAction,
    });
  if (patch.proposedCostImpact !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('proposedCostImpact'),
      value: getUserFriendlyCost(patch.proposedCostImpact) || 'TBD',
    });
  if (patch.approvedCostImpact !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('approvedCostImpact'),
      value: getUserFriendlyCost(patch.approvedCostImpact),
    });
  if (patch.proposedTimeImpact !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('proposedTimeImpact'),
      value: getUserFriendlyNumber(patch.proposedTimeImpact) || 'TBD',
    });
  if (patch.approvedTimeImpact !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('approvedTimeImpact'),
      value: getUserFriendlyNumber(patch.approvedTimeImpact),
    });
  if (patch.reportedBy !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('reportedBy'), value: patch.reportedBy });
  if (patch.ballInCourt !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('ballInCourt'), value: patch.ballInCourt });
  if (patch.subcontractorUserId !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('subcontractorUserId'),
      value: patch.subcontractorUserId,
    });
  if (patch.subcontractorUser !== undefined)
    userFriendlyKeyValues.push({
      key: 'Subcontractor',
      value: getUserFriendlyUser(patch.subcontractorUser),
    });
  if (patch.dateReceived !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('dateReceived'),
      value: patch.dateReceived,
    });
  if (patch.dateSentOut !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('dateSentOut'), value: patch.dateSentOut });
  if (patch.changeOrdersValue !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('changeOrdersValue'),
      value: getUserFriendlyCost(patch.changeOrdersValue),
    });
  if (patch.changeOrdersDaysAdded !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('changeOrdersDaysAdded'),
      value: getUserFriendlyNumber(patch.changeOrdersDaysAdded),
    });
  if (patch.receivedFrom !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('receivedFrom'),
      value: patch.receivedFrom,
    });
  if (patch.typeOfTesting !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('typeOfTesting'),
      value: patch.typeOfTesting,
    });
  if (patch.typeOfTestingOther !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('typeOfTestingOther'),
      value: patch.typeOfTestingOther,
    });
  if (patch.actionTaken !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('actionTaken'), value: patch.actionTaken });
  if (patch.recommendedAction !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('recommendedAction'),
      value: patch.recommendedAction,
    });
  if (patch.revisionNumber !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('revisionNumber'),
      value: getUserFriendlyNumber(patch.revisionNumber),
    });
  if (patch.request !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('request'), value: patch.request });

  if (patch.anticipatedInitialSubmissionDate !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('anticipatedInitialSubmissionDate'),
      value: patch.anticipatedInitialSubmissionDate,
    });
  if (patch.actualSubmissionDate !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('actualSubmissionDate'),
      value: patch.actualSubmissionDate,
    });
  if (patch.dateReturnedToContractor !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('dateReturnedToContractor'),
      value: patch.dateReturnedToContractor,
    });

  if (patch.additionalReviewRecommendedById !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('additionalReviewRecommendedById'),
      value: patch.additionalReviewRecommendedById,
    });
  if (patch.reviewCompleteOn !== undefined)
    userFriendlyKeyValues.push({
      key:
        documentType !== DocumentTemplateType.WarrantyItems
          ? _.startCase('reviewCompleteOn')
          : 'Completed Date',
      value: patch.reviewCompleteOn,
    });
  if (patch.additionalReviewForDocumentId !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('additionalReviewForDocumentId'),
      value: patch.additionalReviewForDocumentId,
    });
  if (patch.pageNumber !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('pageNumber'),
      value: getUserFriendlyNumber(patch.pageNumber),
    });
  if (patch.paragraphNumber !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('paragraphNumber'),
      value: getUserFriendlyNumber(patch.paragraphNumber),
    });
  if (patch.proposedSubstitution !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('proposedSubstitution'),
      value: patch.proposedSubstitution,
    });
  if (patch.substitutionReason !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('substitutionReason'),
      value: patch.substitutionReason,
    });
  if (patch.substitutionAffectsDrawing !== undefined)
    userFriendlyKeyValues.push({
      key: 'Substitution Effect on Drawings',
      value: patch.substitutionAffectsDrawing,
    });
  if (patch.substitutionAffectsTrades !== undefined)
    userFriendlyKeyValues.push({
      key: 'Substitution Effect on Trades',
      value: patch.substitutionAffectsTrades,
    });
  if (patch.substitutionAffectedBy !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('substitutionAffectedBy'),
      value: patch.substitutionAffectedBy,
    });
  if (patch.substitutionDifferences !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('substitutionDifferences'),
      value: patch.substitutionDifferences,
    });
  if (patch.manufacturersWarranties !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('manufacturersWarranties'),
      value: patch.manufacturersWarranties,
    });
  if (patch.manufacturersWarrantiesExplanation !== undefined)
    userFriendlyKeyValues.push({
      key: 'Warranty Explanation',
      value: patch.manufacturersWarrantiesExplanation,
    });
  if (patch.informationAvailable !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('informationAvailable'),
      value: patch.informationAvailable,
    });
  if (patch.submittalPackageDocumentId !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('submittalPackageDocumentId'),
      value: patch.submittalPackageDocumentId,
    });
  if (patch.submittalSectionFileId !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('submittalSectionFileId'),
      value: patch.submittalSectionFileId,
    });
  if (patch.drawingsSectionFileId !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('drawingsSectionFileId'),
      value: patch.drawingsSectionFileId,
    });
  if (patch.completedOn !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('completedOn'), value: patch.completedOn });
  if (patch.subcontractorAssignedOn !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('subcontractorAssignedOn'),
      value: patch.subcontractorAssignedOn,
    });
  if (patch.submittalUploadedOn !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('submittalUploadedOn'),
      value: patch.submittalUploadedOn,
    });
  if (patch.numberOfSheets !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('numberOfSheets'),
      value: getUserFriendlyNumber(patch.numberOfSheets),
    });
  if (patch.shouldSyncProcore !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('shouldSyncProcore'),
      value: patch.shouldSyncProcore ? 'Yes' : 'No',
    });
  if (patch.dateApproved !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('dateApproved'),
      value: patch.dateApproved,
    });
  if (patch.agency !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('agency'), value: patch.agency });
  if (patch.location !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('location'), value: patch.location });
  if (patch.dateContractorCompleted !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('dateContractorCompleted'),
      value: patch.dateContractorCompleted,
    });
  if (patch.licenseNumber !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('licenseNumber'),
      value: patch.licenseNumber,
    });
  if (patch.contactName !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('contactName'), value: patch.contactName });
  if (patch.contactCompany !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('contactCompany'),
      value: patch.contactCompany,
    });
  if (patch.isFinalExecuted !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('isFinalExecuted'),
      value: patch.isFinalExecuted ? 'Yes' : 'No',
    });
  if (patch.dateReturnedToReviewer !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('dateReturnedToReviewer'),
      value: patch.dateReturnedToReviewer,
    });
  if (patch.addendumNumber !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('addendumNumber'),
      value: patch.addendumNumber,
    });
  if (patch.isDraft !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('isDraft'),
      value: patch.isDraft ? 'Yes' : 'No',
    });
  if (patch.weather !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('weather'), value: patch.weather });
  if (patch.partiesPresent !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('partiesPresent'),
      value: patch.partiesPresent ? patch.partiesPresent.join(', ') : 'None',
    });
  if (patch.customArchitect !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('customArchitect'),
      value: patch.customArchitect,
    });
  if (patch.customContractor !== undefined)
    userFriendlyKeyValues.push({
      key: 'Responsible Subcontractor',
      value: patch.customContractor,
    });
  if (patch.currentWorkInProgress !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('currentWorkInProgress'),
      value: patch.currentWorkInProgress,
    });
  if (patch.reviewComment !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('reviewComment'),
      value: patch.reviewComment,
    });
  if (patch.dateOfObservation !== undefined)
    userFriendlyKeyValues.push({
      key:
        documentType === DocumentTemplateType.MeetingMinutes
          ? 'Date Observed'
          : documentType === DocumentTemplateType.ContractorDailyLogs
          ? 'Log Date'
          : 'Date of Observation',
      value: patch.dateOfObservation,
    });
  if (patch.parsedFromFileId !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('parsedFromFileId'),
      value: patch.parsedFromFileId,
    });
  if (patch.procoreId !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('procoreId'),
      value: getUserFriendlyNumber(patch.procoreId),
    });
  if (patch.sheetNumber !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('sheetNumber'), value: patch.sheetNumber });
  if (patch.sheetNumberConfidence !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('sheetNumberConfidence'),
      value: getUserFriendlyNumber(patch.sheetNumberConfidence),
    });
  if (patch.isFileLocked !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('isFileLocked'),
      value: patch.isFileLocked ? 'Yes' : 'No',
    });
  if (patch.additionalReviewForPackageDocumentId !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('additionalReviewForPackageDocumentId'),
      value: patch.additionalReviewForPackageDocumentId,
    });
  if (patch.isProcoreOriginated !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('isProcoreOriginated'),
      value: patch.isProcoreOriginated ? 'Yes' : 'No',
    });
  if (patch.isResponseOnProcore !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('isResponseOnProcore'),
      value: patch.isResponseOnProcore ? 'Yes' : 'No',
    });
  if (patch.procoreDocumentType !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('procoreDocumentType'),
      value: patch.procoreDocumentType ? _.startCase(patch.procoreDocumentType) : null,
    });
  if (patch.punchListStatus !== undefined)
    userFriendlyKeyValues.push({
      key: 'Current Status',
      value: patch.punchListStatus,
    });
  if (patch.physicalLocationId !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('physicalLocationId'),
      value: patch.physicalLocationId,
    });
  if (patch.markedLocation !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('markedLocation'),
      value: JSON.stringify(patch.markedLocation),
    });
  if (patch.parsedParentDocumentId !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('parsedParentDocumentId'),
      value: patch.parsedParentDocumentId,
    });
  if (patch.willPublishResponse !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('willPublishResponse'),
      value: patch.willPublishResponse ? 'Yes' : 'No',
    });
  if (patch.datePublished !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('datePublished'),
      value: patch.datePublished,
    });
  if (patch.dateFinalExecuted !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('dateFinalExecuted'),
      value: patch.dateFinalExecuted,
    });
  if (patch.dateOfLastUpdate !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('dateOfLastUpdate'),
      value: patch.dateOfLastUpdate,
    });
  if (patch.priority !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('priority'), value: patch.priority });
  if (patch.simplePackage !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('simplePackage'),
      value: patch.simplePackage,
    });
  if (patch.expectedCompletionDate !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('expectedCompletionDate'),
      value: patch.expectedCompletionDate,
    });
  if (patch.dateSubmitted !== undefined)
    userFriendlyKeyValues.push({
      key: _.startCase('dateSubmitted'),
      value: patch.dateSubmitted,
    });
  if (patch.createdOn !== undefined)
    userFriendlyKeyValues.push({
      key: 'Created On',
      value: patch.createdOn,
    });
  if (patch.dueDate !== undefined)
    userFriendlyKeyValues.push({ key: _.startCase('dueDate'), value: patch.dueDate });

  return userFriendlyKeyValues;
};

export async function canvasToBlobAsync(canvas: HTMLCanvasElement) {
  return new Promise<Blob>((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) resolve(blob);
    });
  });
}

interface QRCodeOptions {
  link: string;
  width?: number;
  height?: number;
  quietZone?: number;
  titleHeight?: number;
  title?: string;
  titleTop?: number;
  subTitle?: string;
  subTitleTop?: number;
  onRenderingEnd?: (options: any, url: string) => void;
  qrcodeRef: HTMLElement;
  drawer: 'canvas' | 'svg';
}

export const createQRCode = (options: QRCodeOptions) => {
  const {
    link,
    width = 224,
    height = 224,
    quietZone = 0,
    titleHeight,
    title,
    titleTop = 8,
    subTitle,
    subTitleTop = 26,
    onRenderingEnd,
    qrcodeRef,
    drawer,
  } = options;

  let trueTitleHeight = titleHeight;
  if (trueTitleHeight === undefined) {
    trueTitleHeight = 0;
    if (title) trueTitleHeight += 16;
    if (subTitle) trueTitleHeight += 16;
  }

  let subTitleFormatted = subTitle;
  if (subTitleFormatted && subTitleFormatted.length > 38) {
    subTitleFormatted = subTitle?.substring(0, 35) + '...';
  }

  new QRCode(qrcodeRef, {
    text: link,
    logo: qrcodeLogo,
    logoBackgroundColor: '#0947B9',
    width,
    height,
    correctLevel: QRCode.CorrectLevel.H,
    drawer,
    quietZone,
    onRenderingEnd,
    titleHeight: trueTitleHeight,
    title,
    titleTop,
    subTitle: subTitleFormatted,
    subTitleTop,
  });
};

export const getRelevantFieldsByDocumentType = (
  documentType: DocumentTemplateType,
): (keyof INumberedDocumentView)[] => {
  switch (documentType) {
    case DocumentTemplateType.Drawings:
      return ['title', 'sheetNumber'];

    case DocumentTemplateType.Specifications:
      return ['submittalSection', 'submittalSectionDescription'];

    case DocumentTemplateType.CloseoutSubmittals:
    case DocumentTemplateType.Submittals:
      return [
        'assignedToUserId',
        'workflowStatus',
        'generalContractorUserId',
        'architectUserId',
        'submittalSection',
        'submittalSectionDescription',
        'title',
        'actualSubmissionDate',
        'dueDate',
      ];

    case DocumentTemplateType.RequestsForInformation:
    case DocumentTemplateType.WorkChangeProposalRequests:
      return [
        'assignedToUserId',
        'description',
        'workflowStatus',
        'generalContractorUserId',
        'architectUserId',
        'title',
        'referenceNumber',
        'dateSubmitted',
        'dueDate',
      ];

    case DocumentTemplateType.ChangeOrders:
      return [
        'title',
        'description',
        'referenceNumber',
        'changeOrdersValue',
        'changeOrdersDaysAdded',
      ];

    case DocumentTemplateType.RequestsForChange:
    case DocumentTemplateType.PotentialChangeOrders:
      return [
        'referenceNumber',
        'title',
        'description',
        'workflowStatus',
        'assignedToUserId',
        'architectUserId',
        'generalContractorUserId',
        'proposedCostImpact',
        'proposedTimeImpact',
        'dateSubmitted',
        'dueDate',
      ];

    case DocumentTemplateType.PayApplications:
      return [
        'assignedToUserId',
        'description',
        'workflowStatus',
        'generalContractorUserId',
        'architectUserId',
        'title',
        'referenceNumber',
        'dateSubmitted',
        'dueDate',
        'isFinalExecuted',
        'dateFinalExecuted',
      ];

    // description and currentWorkInProgress are listed separately on the export page
    case DocumentTemplateType.FieldReports:
      return [
        'location',
        'weather',
        'contactCompany',
        'dateOfObservation',
        // 'description',
        // 'currentWorkInProgress',
      ];

    case DocumentTemplateType.ContractorDailyLogs:
    case DocumentTemplateType.MeetingMinutes:
      return ['title', 'description', 'dateOfObservation'];

    case DocumentTemplateType.Schedules:
    case DocumentTemplateType.MiscellaneousDocuments:
      return ['title', 'description', 'documentNumber'];

    case DocumentTemplateType.Testing:
      return ['referenceNumber', 'title', 'description', 'agency'];

    case DocumentTemplateType.InformationalItems:
    case DocumentTemplateType.Addenda:
      return ['title', 'documentNumber'];

    case DocumentTemplateType.BidderRfIs:
      return ['workflowStatus', 'description', 'dateSubmitted', 'dueDate'];

    case DocumentTemplateType.SubstitutionRequests:
      return [
        'workflowStatus',
        'submittalSection',
        'pageNumber',
        'paragraphNumber',
        'description',
        'proposedSubstitution',
        'substitutionReason',
        'substitutionAffectsDrawing',
        'substitutionAffectsTrades',
        'substitutionDifferences',
        'manufacturersWarrantiesExplanation',
        'informationAvailable',
        'agency',
        'dateSubmitted',
        'dueDate',
      ];

    case DocumentTemplateType.WarrantyItems:
      return [
        'description',
        'referenceNumber',
        'location',
        'customContractor',
        'expectedCompletionDate',
        'punchListStatus',
        'reviewCompleteOn',
      ];

    default:
      return ['title', 'description', 'referenceNumber'];
  }
};

export const getDocumentIdentifier = (
  document: INumberedDocumentView | null,
  documentType: DocumentTemplateType,
  options: { includeSubmittalTitle?: boolean; isPackage?: boolean } = {},
): string => {
  const { includeSubmittalTitle = false, isPackage = false } = options;

  if (!document) return '';

  switch (documentType) {
    case DocumentTemplateType.Specifications: {
      return `${document.submittalSection} ${document.submittalSectionDescription}`;
    }
    case DocumentTemplateType.CloseoutSubmittals:
    case DocumentTemplateType.Submittals: {
      if (isPackage) {
        return document.referenceNumber!;
      }
      let identifier = document.submittalSection || '';
      if (document.submittalParagraphNumber) identifier += `-${document.submittalParagraphNumber}`;
      if (document.submittalSubparagraphNumber)
        identifier += `-${document.submittalSubparagraphNumber}`;
      if (document.revisionNumber) identifier += `-R${document.revisionNumber}`;
      if (includeSubmittalTitle) {
        if (document.submittalSectionDescription)
          identifier += ` ${document.submittalSectionDescription}`;
        if (document.title) identifier += ` — ${document.title}`;
      }
      return identifier;
    }

    case DocumentTemplateType.ContractorDailyLogs:
    case DocumentTemplateType.MeetingMinutes:
    case DocumentTemplateType.FieldReports: {
      return document.dateOfObservation!;
    }

    case DocumentTemplateType.InformationalItems:
    case DocumentTemplateType.Addenda: {
      return `#${document.documentNumber?.toString().padStart(2, '0')}${
        document.title ? ` — ${document.title}` : ''
      }`;
    }

    case DocumentTemplateType.AsBuilt:
    case DocumentTemplateType.Drawings: {
      return `#${document.sheetNumber}${
        document.revisionNumber ? `-R${document.revisionNumber}` : ''
      }`;
    }

    case DocumentTemplateType.RequestsForInformation:
    case DocumentTemplateType.Task: {
      return `#${document?.referenceNumber}${
        document.revisionNumber ? `-R${document.revisionNumber}` : ''
      }${document.title ? ` — ${document.title}` : ''}`;
    }

    case DocumentTemplateType.WarrantyItems: {
      return `#${document.documentNumber}${
        document.revisionNumber ? `-R${document.revisionNumber}` : ''
      }`;
    }

    default: {
      return `#${document?.referenceNumber || document.documentNumber}${
        document.revisionNumber ? `-R${document.revisionNumber}` : ''
      }`;
    }
  }
};

export const getUserFriendlyConsultantResponse = (response?: ActionTakenType | null) => {
  switch (response) {
    case ActionTakenType.SubmitSpecifiedItem:
      return 'Submit Specified Item';

    case ActionTakenType.ReviseAndResubmit:
      return 'Revise & Resubmit';

    case ActionTakenType.NoExceptionsTaken:
      return 'No Exceptions Taken';

    case ActionTakenType.AmendAsNoted:
      return 'Amend As Noted';

    case ActionTakenType.SeeSubmittalComments:
      return 'See Submittal Comments';

    case ActionTakenType.SeeTransmittalComments:
      return 'See Transmittal Comments';

    case ActionTakenType.RemoveFromPackage:
      return 'Remove from Package';

    default:
      return 'N/A';
  }
};

export const getUserFacingEmailStatus = (status: EmailStatus) => {
  switch (status) {
    case EmailStatus.UnsentWaiting:
      return 'Pending';
    case EmailStatus.Sending:
      return 'Sent';
    case EmailStatus.Delivery:
      return 'Delivered';
    case EmailStatus.Bounce:
    case EmailStatus.Complaint:
    case EmailStatus.OtherFailure:
      return 'Delivery Exception';
  }
};

export const getColorFromEmailStatus = (status: EmailStatus) => {
  switch (status) {
    case EmailStatus.UnsentWaiting:
    case EmailStatus.Sending:
      return '#0947B9';
    case EmailStatus.Delivery:
      return '#128750';
    case EmailStatus.Bounce:
    case EmailStatus.Complaint:
    case EmailStatus.OtherFailure:
      return '#ED3F26';
  }
};

export type FileDataUrl = ArrayBuffer | string | null;

export interface FileWithData {
  file: File;
  data: FileDataUrl;
}

export const readFileAsync = (file: FileWithPath): Promise<FileDataUrl> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

export const docTypesEnableStudioLink = [
  DocumentTemplateType.Submittals,
  DocumentTemplateType.CloseoutSubmittals,
  DocumentTemplateType.SubmittalPackages,
  DocumentTemplateType.CloseoutSubmittalPackages,
  DocumentTemplateType.RequestsForInformation,
  DocumentTemplateType.RequestsForChange,
  DocumentTemplateType.WorkChangeProposalRequests,
  DocumentTemplateType.PotentialChangeOrders,
  DocumentTemplateType.Task,
  DocumentTemplateType.DesignPackages,
];

export const isUserInGroup = (userGroup: IUserGroup, userId: string) => {
  return (
    userGroup.userGroupUserList?.some((u) => u.id === userId) ||
    userGroup.userGroupCompanyList?.some((c) => c.company?.users?.map((u) => u.id).includes(userId))
  );
};

export const convertToBase64 = (file: File | FileWithPath) => {
  return new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const resizeImage = (base64image: string, width: number, height: number) => {
  return new Promise<string>((resolve) => {
    const img = new Image();
    img.src = base64image;

    img.onload = () => {
      // Check if the image require resize at all
      if (img.height <= height && img.width <= width) {
        resolve(base64image);
      } else {
        // Make sure the width and height preserve the original aspect ratio and adjust if needed
        if (img.height > img.width) {
          width = Math.floor(height * (img.width / img.height));
        } else {
          height = Math.floor(width * (img.height / img.width));
        }

        const resizingCanvas: HTMLCanvasElement = document.createElement('canvas');
        const resizingCanvasContext = resizingCanvas.getContext('2d');

        // Start with original image size
        resizingCanvas.width = img.width;
        resizingCanvas.height = img.height;

        // Draw the original image on the (temp) resizing canvas
        resizingCanvasContext?.drawImage(img, 0, 0, resizingCanvas.width, resizingCanvas.height);

        const curImageDimensions = {
          width: Math.floor(img.width),
          height: Math.floor(img.height),
        };

        const halfImageDimensions: { width: number | null; height: number | null } = {
          width: null,
          height: null,
        };

        // Quickly reduce the dize by 50% each time in few itterations until the size is less then
        // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
        // created with direct reduction of very big image to small image
        while (curImageDimensions.width * 0.5 > width) {
          // Reduce the resizing canvas by half and refresh the image
          halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
          halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

          resizingCanvasContext?.drawImage(
            resizingCanvas,
            0,
            0,
            curImageDimensions.width,
            curImageDimensions.height,
            0,
            0,
            halfImageDimensions.width,
            halfImageDimensions.height,
          );

          curImageDimensions.width = halfImageDimensions.width;
          curImageDimensions.height = halfImageDimensions.height;
        }

        // Now do final resize for the resizingCanvas to meet the dimension requirments
        // directly to the output canvas, that will output the final image
        const outputCanvas: HTMLCanvasElement = document.createElement('canvas');
        const outputCanvasContext = outputCanvas.getContext('2d');

        outputCanvas.width = width;
        outputCanvas.height = height;

        outputCanvasContext?.drawImage(
          resizingCanvas,
          0,
          0,
          curImageDimensions.width,
          curImageDimensions.height,
          0,
          0,
          width,
          height,
        );

        // output the canvas pixels as an image. params: format, quality
        resolve(outputCanvas.toDataURL('image/jpeg', 0.9));
      }
    };
  });
};

export function getFileFromBase64(base64String: string, fileName: string) {
  const trimmedString = base64String.replace('data:image/jpeg;base64,', '');
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  const type = 'image/jpeg';
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}

export const resizeImageHelper = async (
  image: File | FileWithPath,
  width: number = 600,
  height: number = 600,
) => {
  const base64image = await convertToBase64(image);
  const resizedBase64image = await resizeImage(base64image, width, height);
  return getFileFromBase64(resizedBase64image, image.name);
};

export const isSurface = () => {
  const isWindows = navigator.userAgent.indexOf('Windows') > -1;
  // @ts-ignore
  const maxTouchPoints = navigator.maxTouchPoints || navigator.msMaxTouchPoints;
  const isTouchable =
    'ontouchstart' in window ||
    maxTouchPoints > 0 ||
    // @ts-ignore
    (window.matchMedia && matchMedia('(any-pointer: coarse)').matches);

  return isWindows && isTouchable;
};
